import { create } from "zustand";

export type TaskType = {
  id: string;
  name: string;
  visible: boolean;
  runrunit_id: number;
  runrunit_team_id: number;
  profile: string;
  title: string | undefined;
  notify: boolean;
};

type State = {
  drawer: boolean;
  progress: boolean;
  data: TaskType;
};

const initialTaskType: TaskType = {
  id: "",
  name: "",
  visible: false,
  runrunit_id: 0,
  runrunit_team_id: 0,
  profile: "",
  title: "",
  notify: false,
};

const initialState: State = {
  drawer: false,
  progress: false,
  data: initialTaskType,
};

type Actions = {
  toggleDrawer: (data?: TaskType) => void;
};

const useTaskTypeStore = create<State & Actions>((set, get) => ({
  ...initialState,

  toggleDrawer: (data?: TaskType) => {
    console.log(data);
    set((state: State) => ({
      drawer: !state.drawer,
      data: data ? data : initialTaskType,
    }));
  },
}));

export default useTaskTypeStore;
