import { Chip } from "@mui/material";

interface Props {
  status: string;
}

type ChipColor =
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning";

const StatusChip = ({ status }: Props) => {
  let color: ChipColor = "primary";
  let label = "Editando";

  switch (status) {
    case "queued":
      label = "Na Fila";
      color = "info";
      break;
    case "processing":
      label = "Processando";
      color = "warning";
      break;
    case "error":
      label = "Erro";
      color = "error";
      break;
    case "done":
      label = "Concluído";
      color = "success";
      break;
  }

  return (
    <Chip
      color={color}
      label={label}
      size="small"
      sx={{ textTransform: "uppercase" }}
    />
  );
};

export default StatusChip;
