import { useEffect, useState } from "react";
import { formatDate, formatTimeAgo, formatHistoryText } from "../../../utils";

import {
  Divider,
  Chip,
  ButtonGroup,
  Button,
  Box,
  LinearProgress,
  CircularProgress,
  Typography,
  Alert,
  Modal,
  AlertTitle,
} from "@mui/material";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@mui/lab";

import NotesIcon from "@mui/icons-material/Notes";

import { fetchData } from "../../../fetchData";
import { useAuth } from "../../../contexts/AuthContext";
import { useOrganization } from ".";
import { useSnackbar } from "../../../contexts/SnackbarContext";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const History = () => {
  const auth = useAuth();
  const organization = useOrganization();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingMoreHidden, setLoadingMoreHidden] = useState(false);
  const [data, setData] = useState<any>([]);
  const limit = 5;
  const [skip, setSkip] = useState(0);
  const [isRemovingNote, setIsRemovingNote] = useState(null);
  const [modal, setModal] = useState({
    open: false,
    item: {
      registered_at: "",
      username: "",
      action: "",
      content: "",
    },
  });

  const handleHistoryApi = async () => {
    setLoading(skip ? false : true);
    setError(false);

    try {
      const result = await fetchData(
        `/organizations/${organization.data._id}/history?limit=${limit}&skip=${skip}`,
        { method: "GET" },
      );

      if (skip) {
        if (result.length) {
          const newData = [...data, ...result];

          setData(newData);
        } else {
          setLoadingMoreHidden(true);
        }
      } else {
        setData(result);
      }
    } catch (error) {
      setError(true);
    }

    setLoadingMore(false);
    setLoading(false);
  };

  useEffect(() => {
    handleHistoryApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  useEffect(() => {
    if (skip) {
      setLoadingMore(true);
    }

    handleHistoryApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, organization.reload.state]);

  const handleLoadMore = () => {
    const newSkip = skip + limit;
    setSkip(newSkip);
  };

  const handleModalClose = () => setModal({ ...modal, open: false });
  const handleModalOpen = (item: any) =>
    setModal({ ...modal, open: true, item });

  const handleRemoveNote = async (id: string) => {
    try {
      await fetchData(`/histories/${id}`, {
        method: "DELETE",
      });
      snackbar?.open("Nota removida com sucesso!", "success");
      organization.reload.set(new Date());
    } catch (error) {
      snackbar?.open(
        "Ops! Ocorreu um erro ao remover a nota. Tente mais tarde.",
        "error",
      );
    }
  };

  const removeNote = (row: any) => {
    return (
      <div>
        {isRemovingNote != row._id && (
          <Button
            color="error"
            size="small"
            sx={{ p: 0, fontSize: 12 }}
            onClick={() => {
              setIsRemovingNote(row._id);
            }}
          >
            Excluir
          </Button>
        )}
        {isRemovingNote == row._id && (
          <ButtonGroup>
            <Button
              color="primary"
              size="small"
              sx={{ py: 0, fontSize: 12 }}
              onClick={() => {
                setIsRemovingNote(null);
              }}
            >
              Cancelar
            </Button>
            <Button
              color="error"
              size="small"
              sx={{ py: 0, fontSize: 12 }}
              onClick={() => {
                handleRemoveNote(row._id);
              }}
            >
              Confirmar
            </Button>
          </ButtonGroup>
        )}
      </div>
    );
  };

  return (
    <div>
      <Divider sx={{ my: 3 }}>
        <Chip label="HISTÓRICO" />
      </Divider>

      {error && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          <AlertTitle>Ops!</AlertTitle>Ocorreu um erro inesperado, tente
          novamente mais tarde.
        </Alert>
      )}

      {loading && <LinearProgress sx={{ mt: 1 }} />}
      {!loading && (
        <div>
          <Timeline position="alternate" sx={{ mb: 0 }}>
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                {formatDate(organization.data.created_at)}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot variant="outlined" />
              </TimelineSeparator>
              <TimelineContent>Organização criada </TimelineContent>
            </TimelineItem>

            {data.map((item: any) => {
              return (
                <TimelineItem key={item._id}>
                  <TimelineOppositeContent color="text.secondary">
                    {formatTimeAgo(item.registered_at)}
                    <Typography variant="body2">por {item.username}</Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot
                      onClick={() => handleModalOpen(item)}
                      sx={
                        item.isComment ? { backgroundColor: "note.main" } : {}
                      }
                    >
                      {item.isComment && (
                        <NotesIcon sx={{ color: "text.primary" }} />
                      )}
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    {item.content}
                    <Typography>
                      {item.action && formatHistoryText(item.action)}
                    </Typography>
                    {item.isComment &&
                      item.user === auth?.user._id &&
                      removeNote(item)}
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </div>
      )}
      {data.length == 0 && !loading && !error && (
        <Alert
          severity="warning"
          sx={{ justifyContent: "center", borderRadius: 1, my: 0 }}
        >
          Nenhum histórico encontrado.
        </Alert>
      )}
      {!loading && data.length > 0 && (
        <Box sx={{ textAlign: "center" }}>
          <Button
            size="small"
            onClick={handleLoadMore}
            sx={{ display: loadingMoreHidden ? "none" : "inline-block" }}
          >
            {loadingMore && <CircularProgress size="1.5rem" />}
            {!loadingMore && "Carregar mais"}
          </Button>
        </Box>
      )}

      <Modal open={modal.open} onClose={handleModalClose}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {formatDate(modal.item.registered_at)}
          </Typography>
          <Typography variant="body2">por {modal.item.username}</Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, wordWrap: "break-word" }}
          >
            {modal.item.action}
            {modal.item.content}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default History;
