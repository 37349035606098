import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Grid, Typography } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import DomainIcon from "@mui/icons-material/Domain";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

import { StyledTextField } from "./styles";

import { fetchData } from "../../../../fetchData";

const Search = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState<any>("");
  const [options, setOptions] = useState<any>([]);
  const [progress, setProgress] = useState(false);
  const limit = 3;

  const fetch = async () => {
    setProgress(true);

    const query = {
      user: `regex=name|${inputValue},login|${inputValue},email.value|${inputValue},client|${inputValue}`,
      org: `regex=name|${inputValue}`,
    };

    // Number Search
    if (!isNaN(inputValue)) {
      query.user = `client=${inputValue}`;
      query.org = `reference=${inputValue}`;
    }

    const user = await fetchData(`/users?${query.user}&limit=${limit}`, {
      method: "GET",
    });

    const org = await fetchData(`/organizations?${query.org}&limit=${limit}`, {
      method: "GET",
    });

    Promise.all([user, org]).then((values) => {
      // console.log([
      //   ...values[1].rows.map((row: any) => ({ type: "org", ...row })),
      //   ...values[0].rows.map((row: any) => ({ type: "user", ...row })),
      // ]);
      setOptions([
        ...values[1].rows.map((row: any) => ({ type: "org", ...row })),
        ...values[0].rows.map((row: any) => ({ type: "user", ...row })),
      ]);
      setProgress(false);
    });
  };

  useEffect(() => {
    setOptions([]);
    setProgress(false);

    if (!inputValue) return;

    const timer = setTimeout(() => fetch(), 500);

    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const handleOnChange = (e: any, object: any) => {
    if (!object) return;

    if (object.type === "user") {
      navigate(
        !object.is_admin ? `/persons/${object._id}` : `/users?id=${object._id}`,
      );
    } else {
      navigate(`/organizations/${object._id}`);
    }

    setValue(null);
    setOptions([]);
  };

  return (
    <Autocomplete
      filterOptions={(x) => x}
      onInputChange={(event: any, newInputValue: any) => {
        setInputValue(newInputValue);
      }}
      popupIcon={<SearchIcon />}
      value={value}
      clearOnBlur
      getOptionLabel={(option: any) => `${option._id}`}
      loading={progress}
      loadingText="Buscando..."
      options={options}
      noOptionsText="Nenhum resultado"
      onChange={handleOnChange}
      blurOnSelect
      onClose={() => {
        setOptions([]);
      }}
      renderInput={(params) => (
        <StyledTextField {...params} value={value} placeholder="Pesquisar..." />
      )}
      renderOption={(props, option: any) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                {option.type === "user" && !option.is_admin && (
                  <PersonIcon sx={{ color: "text.secondary" }} />
                )}
                {option.type === "user" && option.is_admin && (
                  <AssignmentIndIcon sx={{ color: "text.secondary" }} />
                )}
                {option.type === "org" && (
                  <DomainIcon sx={{ color: "text.secondary" }} />
                )}
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                <Typography variant="body2" color="text.secondary">
                  {option.name}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default Search;
