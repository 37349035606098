import { useState } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import StatusChip from "../../../../components/StatusChip";
import ExportExcel from "../../../../components/ExportExcel";

import CloseIcon from "@mui/icons-material/Close";

import { fetchData } from "../../../../fetchData";
import useFopagStore from "../../../../stores/fopag";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import pad from "../../../../utils/pad";
import { formatDate } from "../../../../utils";

const FopagDetailDrawer = () => {
  const snackbar = useSnackbar();
  const data: any = useFopagStore((state) => state.detailDrawerData);
  const state = useFopagStore((state) => state.detailDrawer);
  const close = useFopagStore((state) => state.toggleDetailDrawer);
  const [marvinProgress, setMarvinProgress] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(false);
  const competence = new Date(data.month);

  const handleMarvinCall = async () => {
    setMarvinProgress(true);

    try {
      await fetchData(`/hrm/fopag/${data.reference}/dispatchDraft/${data.id}`, {
        method: "GET",
      });

      close();
      snackbar?.open(
        "Folha enviada para processamento com sucesso!",
        "success",
      );
    } catch (error) {
      snackbar?.open("Ops! Não foi possível realizar a ação.", "error");
    }

    setMarvinProgress(false);
  };

  const handleDownload = async () => {
    setDownloadProgress(true);

    try {
      await fetchData(
        `/hrm/fopag/${data.id}/download`,
        {
          method: "GET",
        },
        false,
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Erro ao fazer o download do arquivo");
          }
          return response.blob(); // O arquivo será retornado como um objeto Blob
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `fopag_${data.reference}_${pad(
            competence.getMonth() + 1,
            2,
          )}-${competence.getFullYear()}.txt`; // Defina o nome do arquivo a ser baixado
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          snackbar?.open("Ops! Não foi possível realizar a ação.", "error");
          console.error(error);
        });

      snackbar?.open("O arquivo será baixado!", "success");
    } catch (error) {
      snackbar?.open("Ops! Não foi possível realizar a ação.", "error");
    }
    setDownloadProgress(false);
  };

  const handleExportData = () => {
    const employees = data.draft?.employees || [];
    let rows: Array<any> = [];

    employees.map((row: any) => {
      let eventos: any = [];

      if (row.events) {
        eventos = row.events.map((evnt: any) => ({
          codigo_empregado: row.i_empregados,
          nome: row.nome,
          evento: evnt.nome,
          valor: evnt.value,
        }));
      } else {
        eventos = [
          {
            codigo_empregado: row.i_empregados,
            nome: row.nome,
            evento: "",
            valor: "",
          },
        ];
      }

      rows = [...rows, ...eventos];
    });

    return rows;
  };

  return (
    <Drawer
      anchor="right"
      open={state}
      onClose={() => close()}
      sx={{ zIndex: 1300 }}
    >
      <Box sx={{ p: 3, pt: 2, width: 300 }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack
            direction="row"
            spacing={1}
            sx={{ flex: 1 }}
            alignItems="center"
          >
            <Typography
              variant="body2"
              sx={{ width: 170, wordWrap: "break-word" }}
            >
              [{data.reference}]{" "}
              {`${pad(
                competence.getMonth() + 1,
                2,
              )}/${competence.getFullYear()}`}
            </Typography>
          </Stack>
          <IconButton onClick={() => close()}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider sx={{ my: 2 }} />
        {data.status !== "editing" && data.status !== "done" && (
          <>
            <Stack direction="column" spacing={2}>
              {data.status === "error" && (
                <Button
                  variant="outlined"
                  fullWidth
                  size="small"
                  onClick={() => handleMarvinCall()}
                >
                  {!marvinProgress && "Reenviar ao Marvin"}
                  {marvinProgress && (
                    <CircularProgress color="inherit" size={18} />
                  )}
                </Button>
              )}
              <Button
                variant="outlined"
                color="success"
                fullWidth
                size="small"
                onClick={() => handleDownload()}
              >
                {!downloadProgress && "Baixar Arquivo"}
                {downloadProgress && (
                  <CircularProgress color="inherit" size={18} />
                )}
              </Button>
            </Stack>
            <Divider sx={{ my: 2 }} />
          </>
        )}
        {data.draft?.employees && data.draft.employees.length > 0 && (
          <ExportExcel
            label="Exportar Informações"
            data={handleExportData()}
            fileName={`${data.reference}_folha_mensal_${pad(
              competence.getMonth() + 1,
              2,
            )}-${competence.getFullYear()}`}
          />
        )}
        <Divider sx={{ my: 2 }} />
        <Typography variant="body1">Detalhes do processo</Typography>
        <br />
        <Stack direction="column">
          <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
            ID do processo
          </Typography>
          <Typography variant="caption">{data.id}</Typography>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Stack direction="row" spacing={1}>
          <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
            Cliente
          </Typography>
          <Typography variant="caption">{data.reference}</Typography>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Stack direction="row" spacing={1}>
          <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
            Competência
          </Typography>
          <Typography variant="caption">{`${pad(
            competence.getMonth() + 1,
            2,
          )}/${competence.getFullYear()}`}</Typography>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Stack direction="row" spacing={1}>
          <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
            Situação
          </Typography>
          <Typography variant="caption">
            <StatusChip status={data.status} />
          </Typography>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Stack direction="column">
          <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
            Criado em
          </Typography>
          <Typography variant="caption">{formatDate(data.month)}</Typography>
        </Stack>
        <Divider sx={{ my: 1 }} />
        {data.sent_in && (
          <Stack direction="column">
            <Typography variant="caption" fontWeight="bold" sx={{ flex: 1 }}>
              Enviado em
            </Typography>
            <Typography variant="caption">
              {formatDate(data.sent_in)}
            </Typography>
          </Stack>
        )}
      </Box>
    </Drawer>
  );
};

export default FopagDetailDrawer;
