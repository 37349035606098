import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
  LinearProgress,
  MenuItem,
  Alert,
  ButtonGroup,
} from "@mui/material";

import { useSnackbar } from "../../../../../contexts/SnackbarContext";
import { useModal } from "../../../../../contexts/ModalContext";
import { useOrganization } from "../..";
import { useAuth } from "../../../../../contexts/AuthContext";

import PinIcon from "@mui/icons-material/Pin";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { fetchData } from "../../../../../fetchData";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

type FormValues = {
  description: string;
  login: string;
  password: string;
  link: string;
  profile: string;
  index?: number | undefined;
};

const PasswordsVault = () => {
  const auth = useAuth();
  const [state, setState] = useState(false);
  const { data: organization, set } = useOrganization();
  const modal = useModal();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("list");
  const [progress, setProgress] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      description: "",
      login: "",
      password: "",
      link: "",
      profile: "",
      index: undefined,
    },
  });

  const showError = () => {
    snackbar?.open(
      "Ops! No momento não foi possível adicionar este login",
      "error",
    );
  };

  const handleTypeChange = (e: any, value: string) => {
    if (value !== null) {
      setType(value);
    }
  };

  const [copiedLoginMap, setCopiedLoginMap] = useState<{
    [key: number]: boolean;
  }>({});
  const [copiedPasswordMap, setCopiedPasswordMap] = useState<{
    [key: number]: boolean;
  }>({});
  const [copiedLinkMap, setCopiedLinkMap] = useState<{
    [key: number]: boolean;
  }>({});

  const loginToClipboard = (id: number, value: string, type: string) => {
    // Copia o valor para o Clipboard
    navigator.clipboard.writeText(value);

    // Define o estado 'copiado' para true para o item específico e tipo (login ou password)
    if (type === "login") {
      setCopiedLoginMap((prev) => ({ ...prev, [id]: true }));
      setTimeout(
        () => setCopiedLoginMap((prev) => ({ ...prev, [id]: false })),
        2000,
      );
    } else if (type === "password") {
      setCopiedPasswordMap((prev) => ({ ...prev, [id]: true }));
      setTimeout(
        () => setCopiedPasswordMap((prev) => ({ ...prev, [id]: false })),
        2000,
      );
    } else if (type === "link") {
      setCopiedLinkMap((prev) => ({ ...prev, [id]: true }));
      setTimeout(
        () => setCopiedLinkMap((prev) => ({ ...prev, [id]: false })),
        2000,
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: "passwords",
      headerName: "Acessos",
      flex: 1,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Stack
            direction="column"
            alignContent="flex-start"
            width="100%"
            spacing={2}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body2" fontWeight="bold">
                {params.row.description}
              </Typography>
              {auth?.user.permission_level !== "operator" && (
                <ButtonGroup
                  variant="outlined"
                  size="small"
                  aria-label="Basic button group"
                >
                  <Button
                    onClick={() =>
                      handleEditDelete(
                        params.row,
                        params.api.getAllRowIds().indexOf(params.id),
                        "edit",
                      )
                    }
                  >
                    <EditIcon fontSize="small" />
                  </Button>
                  <Button
                    color="error"
                    onClick={() =>
                      handleEditDelete(
                        params.row,
                        params.api.getAllRowIds().indexOf(params.id),
                        "delete",
                      )
                    }
                  >
                    <DeleteIcon fontSize="small" />
                  </Button>
                </ButtonGroup>
              )}
            </Stack>
            <Divider></Divider>
            <Stack direction="column" alignItems="center" spacing={1}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography variant="caption">URL:</Typography>
                <Chip
                  label={
                    copiedLinkMap[params.row.id] ? "Copiado!" : params.row.link
                  }
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    loginToClipboard(params.row.id, params.row.link, "link");
                  }}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography variant="caption">Login:</Typography>
                <Chip
                  label={
                    copiedLoginMap[params.row.id]
                      ? "Copiado!"
                      : params.row.login
                  }
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    loginToClipboard(params.row.id, params.row.login, "login");
                  }}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography variant="caption">Senha:</Typography>
                <Chip
                  label={
                    copiedPasswordMap[params.row.id]
                      ? "Copiado!"
                      : params.row.password
                  }
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    loginToClipboard(
                      params.row.id,
                      params.row.password,
                      "password",
                    );
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        );
      },
    },
  ];

  async function onSubmit(data: FormValues) {
    setProgress(true);
    let current = organization.passwords;
    let snackTxt = "salva";

    if (type === "edit") {
      snackTxt = "editada";
      current = current.map((row: any, index: number) => {
        if (index === data.index) {
          return data;
        }

        return row;
      });
    }

    if (type === "delete") {
      snackTxt = "removida";
      current = current.filter(
        (row: any, index: number) => index !== data.index,
      );
    }

    const passwords = type === "add" ? [...current, { ...data }] : current;

    const payload = {
      ...organization,
      passwords,
    };

    try {
      await fetchData(`/organizations/${organization._id}`, {
        method: "PUT",
        body: JSON.stringify(payload),
      });

      set(payload);
      snackbar?.open(`Senha ${snackTxt} com sucesso!`, "success");

      setType("list");
    } catch (error) {
      snackbar?.open(
        "Ops! No momento não foi possível realizar a ação.",
        "error",
      );
    }

    setProgress(false);
  }

  function handleEditDelete(data: FormValues, index: number, type: string) {
    setValue("description", data.description);
    setValue("login", data.login);
    setValue("password", data.password);
    setValue("link", data.link);
    setValue("profile", data.profile);
    setValue("index", index);
    setType(type);
  }

  function resetForm() {
    setValue("description", "");
    setValue("login", "");
    setValue("password", "");
    setValue("link", "");
    setValue("profile", "");
    setValue("index", undefined);
  }

  useEffect(() => {
    if (type === "list") {
      resetForm();
    }
  }, [type]);

  return (
    <>
      <Button
        size="small"
        color="primary"
        variant="outlined"
        onClick={() => {
          setState(true);
        }}
      >
        <PinIcon sx={{ mr: 1 }} /> Gerenciador de Senhas
      </Button>
      <Drawer
        anchor="right"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 300, height: "100%" }}>
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <PinIcon />
                <Typography
                  variant="body2"
                  sx={{ width: 170, wordWrap: "break-word" }}
                >
                  Gerenciador de Senhas
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider />
            {auth?.user.permission_level !== "operator" && (
              <ToggleButtonGroup
                color="primary"
                value={type}
                exclusive
                onChange={handleTypeChange}
                fullWidth
                size="small"
              >
                <ToggleButton value="list">Acessos</ToggleButton>
                {type !== "edit" && type !== "delete" && (
                  <ToggleButton value="add">Adicionar</ToggleButton>
                )}
                {type === "edit" && (
                  <ToggleButton value="edit">Editar</ToggleButton>
                )}
                {type === "delete" && (
                  <ToggleButton value="delete">Remover</ToggleButton>
                )}
              </ToggleButtonGroup>
            )}
            {type !== "list" && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  {...register("description", {
                    required: "Informe a descrição.",
                  })}
                  label="Descrição"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  size="small"
                  disabled={type === "delete"}
                />
                {errors?.description && (
                  <Alert severity="error">
                    {errors.description.message?.toString()}
                  </Alert>
                )}
                <TextField
                  {...register("link")}
                  label="Link de acesso"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  size="small"
                  disabled={type === "delete"}
                />
                <TextField
                  {...register("login", {
                    required: "Informe o login.",
                  })}
                  label="Login"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  size="small"
                  disabled={type === "delete"}
                />
                {errors?.login && (
                  <Alert severity="error">
                    {errors.login.message?.toString()}
                  </Alert>
                )}
                <TextField
                  {...register("password", {
                    required: "Informe a senha.",
                  })}
                  label="Senha"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  size="small"
                  disabled={type === "delete"}
                />
                {errors?.password && (
                  <Alert severity="error">
                    {errors.password.message?.toString()}
                  </Alert>
                )}
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Perfil"
                      size="small"
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      disabled={type === "delete"}
                    >
                      {["", "Geral", "Inteligência Financeira"].map(
                        (label: string) => (
                          <MenuItem key={label} value={label}>
                            {label}
                          </MenuItem>
                        ),
                      )}
                    </TextField>
                  )}
                  control={control}
                  name="profile"
                />
                <TextField {...register("index")} type="hidden" />
                <Button
                  size="medium"
                  fullWidth
                  variant="contained"
                  color={type !== "delete" ? "primary" : "error"}
                  type="submit"
                  sx={{ mt: 1 }}
                >
                  {type !== "delete" ? "Salvar" : "Remover"}
                </Button>
                {type !== "add" && (
                  <Button
                    size="medium"
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    type="button"
                    sx={{ mt: 1 }}
                    onClick={() => setType("list")}
                  >
                    Cancelar
                  </Button>
                )}
              </form>
            )}
            {type === "list" && (
              <Box sx={{ flex: 1 }}>
                <DataGrid
                  columnHeaderHeight={0}
                  rowSelection={false}
                  rows={organization.passwords.filter((row: FormValues) => {
                    if (auth?.user.permission_level === "operator") {
                      return row.profile !== "Inteligência Financeira";
                    }

                    return row;
                  })}
                  rowHeight={180}
                  columns={columns}
                  getRowId={(row) => row?.description}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 4,
                      },
                    },
                  }}
                  pageSizeOptions={[4]}
                  sx={{ flex: 1 }}
                />
              </Box>
            )}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default PasswordsVault;
