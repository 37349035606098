import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  DataGrid as XDataGrid,
  DataGridProps,
  GridPaginationModel,
  ptBR,
} from "@mui/x-data-grid";

import useDataGridFilterStore from "../../stores/dataGridFilter";

interface Props {
  columns: any;
  model: string;
  mapping: Record<string, any>;
  paginationModel: GridPaginationModel;
  sort?: string;
  order?: string;
}

const DataGridFilter = (props: Props) => {
  const location: any = useLocation();
  const { model, mapping } = props;
  const [paginationModel, setPaginationModel] = useState(props.paginationModel);
  const loading = useDataGridFilterStore((state) => state.progress);
  const rows = useDataGridFilterStore((state) => state.data);
  const rowsCount = useDataGridFilterStore((state) => state.rows);
  const setInitParams = useDataGridFilterStore((state) => state.initParams);

  useEffect(() => {
    setInitParams(
      model,
      mapping,
      paginationModel.pageSize,
      paginationModel.page * paginationModel.pageSize,
      props.sort,
      props.order,
      location.state?.filter,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel]);

  return (
    <XDataGrid
      rows={rows}
      loading={loading}
      columns={props.columns}
      rowCount={rowsCount}
      pageSizeOptions={[props.paginationModel.pageSize]}
      paginationModel={paginationModel}
      paginationMode="server"
      onPaginationModelChange={setPaginationModel}
      localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      disableColumnSelector={true}
      disableRowSelectionOnClick={true}
      disableColumnMenu={true}
      // onCellClick={(params)=>{console.log(params)}}
    />
  );
};

DataGridFilter.defaultProps = {
  paginationModel: {
    page: 0,
    pageSize: 25,
  },
  sort: null,
  order: null,
};

export default DataGridFilter;
