import very_dissatisfied from "../assets/images/angry.svg";
import dissatisfied from "../assets/images/sad.svg";
import neutral from "../assets/images/neutral.svg";
import satisfied from "../assets/images/smile.svg";
import very_satisfied from "../assets/images/bigsmile.svg";

export const contactLabels = [
  {
    label: "Residencial",
    value: "residencial",
  },
  {
    label: "Trabalho",
    value: "work",
  },
  {
    label: "Outro",
    value: "other",
  },
];

export const phoneLabels = contactLabels;

phoneLabels.unshift({
  label: "Celular",
  value: "mobile",
});

phoneLabels.unshift({
  label: "WhatsApp",
  value: "whatsapp",
});

export const userProfile = [
  {
    label: "Gestor",
    value: "manager",
  },
  {
    label: "Operador",
    value: "operator",
  },
  {
    label: "Inteligência Financeira",
    value: "financial",
  },
];

export const organizationsProfileLabels = [
  "Empresas",
  "Igrejas",
  "Terceiro Setor",
];

export const organizationsRegimeTributarioLabels = [
  "Imune",
  "SIMPLES Nacional",
  "Lucro Presumido",
  "PF",
  "Lucro Real",
  "MEI",
];

export const organizationsAreasDeAtuacaoLabels = [
  "Igreja",
  "Associação",
  "Empresa Serviços",
  "Empresa Comércio",
  "Associação com atividade de comércio",
  "Pessoa Física",
  "Empresa Indústria",
];

export const organizationsDeliveryWaysLabels = [
  "Físico",
  "Atlântico Digital",
  "Software",
  "E-mail",
];

export const organizationsFinancialControlsLabels = [
  "Software",
  "Planilha Atlântico",
  "Planilha Cliente",
];

export const CsatIcon = (answer: string) => {
  if (answer === "very_dissatisfied") {
    return very_dissatisfied;
  } else if (answer === "dissatisfied") {
    return dissatisfied;
  } else if (answer === "neutral") {
    return neutral;
  } else if (answer === "satisfied") {
    return satisfied;
  } else if (answer === "very_satisfied") {
    return very_satisfied;
  }
};

export const NpsIcon = (answer: number) => {
  if (answer < 7) {
    return very_dissatisfied;
  } else if (answer > 6 && answer < 9) {
    return neutral;
  } else {
    return very_satisfied;
  }
};

export const npsOptions = ["E-mail"];

export const npsStatusSwitch = (status: string, reverse = false) => {
  let output;

  switch (status) {
    case "done":
    case "Concluído":
      output = reverse ? "done" : "Concluído";
      break;
    case "editing":
    case "Rascunho":
      output = reverse ? "editing" : "Rascunho";
      break;
    case "sended":
    case "Enviado":
      output = reverse ? "sended" : "Enviado";
      break;
  }

  return output;
};
