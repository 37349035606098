import { create } from "zustand";

type State = {
  detailDrawer: boolean;
  detailDrawerData: object;
};

const initialState: State = {
  detailDrawer: false,
  detailDrawerData: {},
};

type Actions = {
  toggleDetailDrawer: (data?: object) => void;
};

const useFopagStore = create<State & Actions>((set, get) => ({
  ...initialState,

  toggleDetailDrawer: (data?: object) => {
    set((state: State) => ({
      detailDrawer: !state.detailDrawer,
      detailDrawerData: data ? data : {},
    }));
  },
}));

export default useFopagStore;
