import { ReactHTML, useEffect, useState } from "react";

import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";

import useDataGridFilterStore from "../../../../stores/dataGridFilter";
import { organizationsProfileLabels } from "../../../../utils/labels";

const UsersOptionsDrawer = () => {
  const state = useDataGridFilterStore((state) => state.drawer.state);
  // const data: any = useDataGridFilterStore((state) => state.drawer.data);
  const order = useDataGridFilterStore((state) => state.order);
  const changeOrder = useDataGridFilterStore((state) => state.changeOrder);
  const sort = useDataGridFilterStore((state) => state.sort);
  const changeSort = useDataGridFilterStore((state) => state.changeSort);
  const filter = useDataGridFilterStore((state) => state.filter);
  const changeFilter = useDataGridFilterStore((state) => state.changeFilter);
  const resetFilter = useDataGridFilterStore((state) => state.resetFilter);
  const toggleDrawer = useDataGridFilterStore((state) => state.toggleDrawer);

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { type, value, name } = e.target;
    changeFilter({ ...filter, [name]: value }, type);
  };

  const handleOrder = (
    event: React.MouseEvent<HTMLElement>,
    newOrder: string | null,
  ) => {
    changeOrder(newOrder);
  };

  const handleSort = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    changeSort(newValue);
  };

  return (
    <Drawer
      anchor="right"
      open={state}
      onClose={() => {
        toggleDrawer();
      }}
    >
      <Box sx={{ p: 3, pt: 2, width: 300 }} width={300} height="100%">
        <Stack height="100%" spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack
              direction="row"
              spacing={1}
              sx={{ flex: 1 }}
              alignItems="center"
            >
              <Typography
                variant="body1"
                sx={{ width: 170, wordWrap: "break-word" }}
              >
                Opções
              </Typography>
            </Stack>
            <IconButton
              onClick={() => {
                toggleDrawer();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" fontWeight="bold">
              Ordenação
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={order}
              exclusive
              onChange={handleOrder}
              aria-label="text alignment"
              size="small"
            >
              <ToggleButton value="" aria-label="ascending order">
                <SouthIcon fontSize="small" />
              </ToggleButton>
              <ToggleButton value="desc" aria-label="descending order">
                <NorthIcon fontSize="small" />
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <ToggleButtonGroup
            color="primary"
            value={sort}
            exclusive
            onChange={handleSort}
            aria-label="Ordernar Por"
            size="small"
            fullWidth
          >
            <ToggleButton value="">Nome</ToggleButton>
            <ToggleButton value="login">Login</ToggleButton>
          </ToggleButtonGroup>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body2" fontWeight="bold">
            Filtros
          </Typography>
          <TextField
            label="Nome"
            variant="outlined"
            size="small"
            name="name"
            value={filter?.name || ""}
            onChange={handleFilterChange}
          />
          <TextField
            select
            label="Situação"
            variant="outlined"
            size="small"
            name="status"
            value={filter?.status || ""}
            onChange={handleFilterChange}
          >
            <MenuItem value="">Ambos</MenuItem>
            {[
              { label: "Ativo", value: "true" },
              { label: "Inativo", value: "false" },
            ].map((option: any) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {Object.keys(filter).length > 0 && (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              fullWidth
              onClick={() => resetFilter()}
            >
              Limpar Filtro
            </Button>
          )}
        </Stack>
      </Box>
    </Drawer>
  );
};

export default UsersOptionsDrawer;
