import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DataGrid from "../../../components/DataGrid";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ViewContent from "../../../components/ViewContent";

import {
  Alert,
  AlertTitle,
  Chip,
  Stack,
  Link,
  Button,
  Typography,
  IconButton,
  Badge,
} from "@mui/material";

import { formatDate } from "../../../utils";
import { npsStatusSwitch } from "../../../utils/labels";

import { useAuth } from "../../../contexts/AuthContext";
import useDataGridFilterStore from "../../../stores/dataGridFilter";

import DataGridFilter from "../../../components/DataGridFilter";
import NpsOptionsDrawer from "./drawers/options";

const Nps = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const error = useDataGridFilterStore((state) => state.error);
  const filter = useDataGridFilterStore((state) => state.filter);
  const toggleDrawer = useDataGridFilterStore((state) => state.toggleDrawer);
  const model = "surveys/nps";
  const mapping = {
    _id: "id",
    title: "title",
    option: "option",
    status: "status",
    created_at: "created_at",
    published_up: "published_up",
    published_down: "published_down",
    sended_at: "sended_at",
  };

  const columns = [
    {
      field: "title",
      headerName: "Pesquisa",
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Link
              onClick={(e) => {
                e.preventDefault();

                navigate(
                  `/surveys/nps/${
                    params.row.status === "editing" &&
                    auth?.user.permission_level === "manager"
                      ? "edit/"
                      : ""
                  }${params.id}`,
                );
              }}
              sx={{
                overflow: "hidden",
                justifyContent: "left",
                cursor: "pointer",
              }}
            >
              {params.row.title}
            </Link>
            <Chip
              color={params.row.status != "editing" ? "success" : "default"}
              label={npsStatusSwitch(params.row.status)}
              size="small"
              sx={{ textTransform: "uppercase", ml: 1 }}
            />
          </>
        );
      },
      minWidth: 150,
    },
    {
      field: "created_at",
      headerName: "Data",
      width: 200,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Stack direction="column">
            <>
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {params.row.status === "editing" && "Criado em"}
                {params.row.status === "done" && "Concluído em"}
                {params.row.status === "sended" && "Enviado em"}
              </Typography>
              <Typography variant="body2" component="div">
                {params.row.status === "editing" &&
                  formatDate(params.row.created_at, "D/M/Y H:i")}
                {params.row.status === "done" &&
                  formatDate(params.row.published_down, "D/M/Y H:i")}
                {params.row.status === "sended" &&
                  formatDate(params.row.sended_at, "D/M/Y H:i")}
              </Typography>
            </>
          </Stack>
        );
      },
    },
  ];

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <ViewContent
      title="Pesquisas NPS"
      icon={<DonutSmallIcon sx={{ mr: 2 }} />}
      button={
        <IconButton
          onClick={() => toggleDrawer()}
          color="white"
          aria-label="options"
        >
          <Badge badgeContent={Object.keys(filter).length} color="secondary">
            <MoreHorizIcon />
          </Badge>
        </IconButton>
      }
    >
      <DataGridFilter
        model={model}
        mapping={mapping}
        columns={columns}
        sort="created_at"
        order="desc"
      />
      <NpsOptionsDrawer />
    </ViewContent>
  );
};

export default Nps;
