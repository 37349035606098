import { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";

import {
  Alert,
  AlertTitle,
  Drawer,
  Box,
  Stack,
  Typography,
  Divider,
  IconButton,
  LinearProgress,
  CircularProgress,
  TextField,
  MenuItem,
  Switch,
  Button,
  FormControlLabel,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { fetchData } from "../../../../fetchData";
import {
  userProfile,
  organizationsProfileLabels,
  contactLabels,
} from "../../../../utils/labels";

import { profiles } from "../../../../utils/profileHandler";

import { useSnackbar } from "../../../../contexts/SnackbarContext";
import useTaskTypeStore from "../../../../stores/taskType";
import { TaskType } from "../../../../stores/taskType";

const TaskTypeDrawer = () => {
  const snackbar = useSnackbar();
  const [error, setError] = useState(false);
  const [user, setUser] = useState<any>({});
  const data = useTaskTypeStore((state) => state.data);
  const drawer = useTaskTypeStore((state) => state.drawer);
  const progress = useTaskTypeStore((state) => state.progress);
  const toggleDrawer = useTaskTypeStore((state) => state.toggleDrawer);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<TaskType>({
    defaultValues: data,
  });

  useEffect(() => reset(data), [data]);

  async function onSubmit(data: any) {
    data.status = data.status === "true";
    data.profile = [data.profile];
    data.email = [
      {
        value: data.email,
        primary: true,
        label: "work",
      },
    ];

    // try {
    //   await fetchData(`/users/${id}`, {
    //     method: "PUT",
    //     body: JSON.stringify({ ...user, ...data }),
    //   });

    //   // Reload data from store
    //   toggleDrawer();
    //   snackbar?.open("Usuário atualizado com sucesso!", "success");
    // } catch (error) {
    //   console.log(error);
    //   snackbar?.open(
    //     "Ops! No momento não foi possível realizar a ação.",
    //     "error",
    //   );
    // }
  }

  const handleNotifyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.checked);
    setValue("notify", event.target.checked);
  };

  return (
    <Drawer
      anchor="right"
      open={drawer}
      onClose={() => {
        toggleDrawer();
      }}
    >
      <Box sx={{ p: 3, pt: 2, width: 300 }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            variant="body2"
            sx={{ width: 170, wordWrap: "break-word", flex: 1 }}
          >
            Editar tipo de tarefa
          </Typography>
          <IconButton
            onClick={() => {
              toggleDrawer();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider sx={{ my: 2 }} />
        {error && (
          <Alert
            severity="error"
            sx={{
              mt: 1,
              flexDirection: "column",
              alignContent: "center",
              textAlign: "center",
              ".MuiAlert-icon": {
                margin: "0 auto",
              },
            }}
          >
            <AlertTitle>Ops!</AlertTitle>
            Um erro inesperado ocorreu. Tente novamente mais tarde.
          </Alert>
        )}
        {!error && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register("name", {
                required: "Informe o nome do tarefa.",
              })}
              label="Nome"
              variant="outlined"
              fullWidth
              margin="dense"
              size="small"
              error={errors?.name ? true : false}
            />
            {errors?.name && (
              <Alert severity="error">{errors.name.message?.toString()}</Alert>
            )}

            <Divider sx={{ my: 2 }} />

            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Perfil"
                  size="small"
                  margin="dense"
                  fullWidth
                  variant="outlined"
                >
                  {profiles.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              control={control}
              name="profile"
            />
            {errors?.profile && (
              <Alert severity="error">
                {errors.profile.message?.toString()}
              </Alert>
            )}

            <Divider sx={{ my: 2 }} />

            <Controller
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={<Switch checked={value} onChange={onChange} />}
                  label="Notificação"
                />
              )}
              control={control}
              name="notify"
            />

            <Divider sx={{ my: 2 }} />

            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => {
                  toggleDrawer();
                }}
              >
                Cancelar
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                type="submit"
              >
                {!progress && "Salvar"}
                {progress && (
                  <CircularProgress size={12} sx={{ color: "#FFF" }} />
                )}
              </Button>
            </Stack>
          </form>
        )}
      </Box>
    </Drawer>
  );
};

export default TaskTypeDrawer;
