import { create } from "zustand";
import { fetchData } from "../fetchData";

type DrawerState = {
  state: boolean;
  data?: object;
};

type State = {
  drawer: DrawerState;
  data: object[];
  progress: boolean;
  error: boolean;
  rows: number;
  sort: string;
  order: string;
  filter: Record<string, any>;
  timer: any;
  mapping: Record<string, any>;
  model: string | undefined;
  limit: number;
  skip: number;
};

const initialDrawerState: DrawerState = {
  state: false,
  data: {},
};

const initialState: State = {
  drawer: initialDrawerState,
  data: [],
  progress: false,
  error: false,
  rows: 0,
  sort: "",
  order: "",
  filter: {},
  timer: undefined,
  mapping: {},
  model: undefined,
  limit: 25,
  skip: 0,
};

type Actions = {
  toggleDrawer: (data?: object) => void;
  getData: () => void;
  initParams: (
    model: string,
    mapping: Record<string, any>,
    limit: number,
    skip: number,
    sort?: string | null,
    order?: string | null,
    filter?: Record<string, any> | undefined,
  ) => void;
  changeOrder: (order: string | null) => void;
  changeSort: (sort: string | null) => void;
  changeFilter: (filter: Record<string, any>, type?: string) => void;
  objectToQueryString: (obj: Record<string, any>) => string;
  cleanFilterObject: (obj: Record<string, any>) => Record<string, any>;
  mapRows: (
    rows: Record<string, any>,
    mapping: Record<string, any>,
  ) => object[];
  resetFilter: () => void;
};

const useDataGridFilterStore = create<State & Actions>((set, get) => ({
  ...initialState,

  initParams: (
    model: string,
    mapping: Record<string, any>,
    limit: number,
    skip: number,
    sort = null,
    order = null,
    filter?: Record<string, any> | undefined,
  ) => {
    if (get().model !== model) {
      set(() => ({
        ...initialState,
        mapping,
        model,
        limit,
      }));
    }

    if (sort) {
      set(() => ({
        sort,
      }));
    }

    if (order) {
      set(() => ({
        order,
      }));
    }

    if (filter) {
      const newFilter = get().cleanFilterObject(filter);
      set(() => ({
        filter: Object.keys(newFilter).length ? newFilter : {},
      }));
    }

    set(() => ({
      skip,
    }));

    get().getData();
  },

  toggleDrawer: (data?: object) => {
    set((state: State) => ({
      drawer: {
        state: !state.drawer.state,
        data: data ? data : {},
      },
    }));
  },

  changeOrder: (order?: string | null) => {
    set(() => ({
      order: order ? "desc" : "",
    }));

    get().getData();
  },

  changeSort: (sort?: string | null) => {
    set(() => ({
      sort: sort ? sort : "",
    }));

    get().getData();
  },

  objectToQueryString(obj: Record<string, any>): string {
    const params = new URLSearchParams();

    for (const key in obj) {
      if (
        // eslint-disable-next-line no-prototype-builtins
        obj.hasOwnProperty(key) &&
        obj[key] !== undefined &&
        obj[key] !== null &&
        obj[key] !== ""
      ) {
        if (
          key === "name" ||
          key === "title" ||
          key === "phone.value" ||
          key === "login" ||
          key === "email.value"
        ) {
          params.append("regex", `${key}|${obj[key]}`);
        } else {
          params.append(key, obj[key]);
        }
      }
    }

    return params.toString();
  },

  cleanFilterObject(obj: Record<string, any>): Record<string, any> {
    const cleanedObj: any = {};
    for (const key in obj) {
      if (
        Object.prototype.hasOwnProperty.call(obj, key) &&
        obj[key] !== null &&
        obj[key] !== ""
      ) {
        cleanedObj[key] = obj[key];
      }
    }
    return cleanedObj;
  },

  changeFilter: (filter: Record<string, any>, type?: string) => {
    const newFilter = get().cleanFilterObject(filter);

    set(() => ({
      filter: Object.keys(newFilter).length ? newFilter : {},
      skip: 0,
    }));

    // console.log(get().filter);

    if (type) {
      if (get().timer) {
        clearTimeout(get().timer);
      }

      set(() => ({
        timer: setTimeout(() => {
          get().getData();
          set(() => ({ timer: undefined }));
        }, 500),
      }));
    } else {
      get().getData();
    }
  },

  mapRows: (rows, mapping) => {
    return rows.map((item: any) => {
      const mappedItem: any = {};
      for (const key in mapping) {
        const newKey = mapping[key];
        const value = item[key];
        mappedItem[newKey] = value;
      }
      return mappedItem;
    });
  },

  getData: async () => {
    const model = get().model;
    const sort = get().sort;
    const order = get().order;
    const filter = get().objectToQueryString(get().filter || {});
    set(() => ({ data: initialState.data }));
    set(() => ({ progress: true }));
    const hasQuestionMark = model ? model.includes("?") : false;
    const url = `/${hasQuestionMark ? `${model}` : `${model}?`}${
      sort && `sort=${sort}&`
    }${order && `order=${order}&`}limit=${get().limit}&skip=${get().skip}${
      filter && `&${filter}`
    }`;

    if (!model) return;

    // console.log(get().mapping);

    try {
      const result = await fetchData(url, {
        method: "GET",
      });

      if (result) {
        const rows = get().mapRows(result.rows, get().mapping);
        set(() => ({ rows: result.count }));
        set(() => ({
          data: rows,
        }));
        // console.log(rows);
      } else {
        throw new Error("Nenhum resultado obtido!");
      }
    } catch (error) {
      set(() => ({ error: true }));
      console.log(error);
    }

    set(() => ({ progress: false }));
  },

  resetFilter: () => {
    set(() => ({
      filter: {},
    }));
    get().getData();
  },
}));

export default useDataGridFilterStore;
