import { useNavigate } from "react-router-dom";
import DataGridFilter from "../../../components/DataGridFilter";
import GroupIcon from "@mui/icons-material/GroupOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import ViewContent from "../../../components/ViewContent";

import {
  Alert,
  AlertTitle,
  Chip,
  Stack,
  Link,
  IconButton,
  Badge,
  Switch,
} from "@mui/material";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import { navigateToOrganization } from "../../../utils/navigateToOrganization";
import useDataGridFilterStore from "../../../stores/dataGridFilter";
import TaskTypesOptionsDrawer from "./drawers/options";
import TaskTypeDrawer from "./drawers/detail";

import { profileSwitch } from "../../../utils/profileHandler";
import useTaskTypeStore from "../../../stores/taskType";

const TaskTypes = () => {
  const navigate = useNavigate();
  const detailDrawer = useTaskTypeStore((state) => state.toggleDrawer);
  const error = useDataGridFilterStore((state) => state.error);
  const filter = useDataGridFilterStore((state) => state.filter);
  const toggleDrawer = useDataGridFilterStore((state) => state.toggleDrawer);
  const model = "task-types";
  const mapping = {
    _id: "id",
    name: "name",
    runrunit_id: "runrunit_id",
    runrunit_team_id: "runrunit_team_id",
    visible: "visible",
    notify: "notify",
    profile: "profile",
    title: "title",
  };

  const columns = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Link
            onClick={(e) => {
              e.preventDefault();
              detailDrawer(params.row);
            }}
            sx={{
              overflow: "hidden",
              justifyContent: "left",
              cursor: "pointer",
            }}
          >
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: "profile",
      headerName: "Perfil",
      width: 170,
      sortable: false,
      renderCell: (params: any) => profileSwitch(params.row.profile),
    },
    {
      field: "notify",
      headerName: "Notificação",
      width: 140,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Switch
            inputProps={{
              "aria-label": params.row.notify
                ? "Notificação ativa"
                : "Notificação inativa",
            }}
            disabled
            checked={params.row.notify}
          />
        );
      },
    },
  ];

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <ViewContent
      title="Tipos de Tarefa"
      icon={<AssignmentTurnedInIcon sx={{ mr: 2 }} />}
      button={
        <IconButton
          onClick={() => toggleDrawer()}
          color="white"
          aria-label="options"
        >
          <Badge badgeContent={Object.keys(filter).length} color="secondary">
            <MoreHorizIcon />
          </Badge>
        </IconButton>
      }
    >
      <DataGridFilter model={model} mapping={mapping} columns={columns} />
      <TaskTypesOptionsDrawer />
      <TaskTypeDrawer />
    </ViewContent>
  );
};

export default TaskTypes;
