import { useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";

import {
  Alert,
  MenuItem,
  TextField,
  Divider,
  Button,
  Stack,
  CircularProgress,
  Tabs,
  Tab,
  Grid,
} from "@mui/material";

import DatePickerField from "../../../components/DatePickerField";

import { organizationsProfileLabels } from "../../../utils/labels";

import { fetchData } from "../../../fetchData";

import { useSnackbar } from "../../../contexts/SnackbarContext";

import { useOrganization } from ".";

import { TabPanel } from "../Person/tabs";

import PlaceIcon from "@mui/icons-material/Place";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";

interface Props {
  setIsEditing: any;
}

type FormValues = {
  name: string;
  fantasy: string;
  document: string;
  reference: number;
  profile: Array<string>;
  offboarding: Date;
  init_date: Date;
  status: boolean;
  critical: boolean;
  address: {
    zipcode: string;
    street: string;
    number: string;
    complement: string;
    district: string;
    city: string;
    state: string;
  };
  delivery_address: {
    zipcode: string;
    street: string;
    number: string;
    complement: string;
    district: string;
    city: string;
    state: string;
  };
  questor_branch_id: number;
  iugu_id: string;
  zendesk_id: number;
  runrunit_id: number;
  runrunit_projects: any;
};

const Edit = (props: Props) => {
  const snackbar = useSnackbar();
  const { data: organization, set } = useOrganization();
  const [progress, setProgress] = useState(false);
  const [tab, setTab] = useState(0);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      status: organization.status.toString(),
      critical: organization.critical
        ? organization.critical.toString()
        : "false",
      name: organization.name,
      fantasy: organization.fantasy,
      document: organization.document,
      reference: organization.reference,
      profile: organization.profile || "",
      address: organization.address,
      delivery_address: organization.delivery_address,
      questor_branch_id: organization.questor_branch_id,
      iugu_id: organization.iugu_id,
      zendesk_id: organization.zendesk_id,
      runrunit_id: organization.runrunit_id,
      runrunit_projects: organization.runrunit_projects,
      offboarding: organization.offboarding,
      init_date: organization.init_date,
    },
  });

  const {
    fields: runrunit_projects,
    append: addProject,
    remove: removeProject,
  } = useFieldArray({
    control,
    name: "runrunit_projects",
  });

  const watchRrProjects = watch("runrunit_projects");
  const status = watch("status");

  const controlledRrProjects = runrunit_projects.map((field, index) => {
    return {
      ...field,
      ...watchRrProjects[index],
    };
  });

  const handleTabChange = (event: any, newValue: any) => {
    setTab(newValue);
  };

  async function onSubmit(data: any) {
    setProgress(true);

    data.status = data.status === "true";
    data.critical = data.critical === "true";

    if (!data.questor_branch_id) delete data.questor_branch_id;

    if (!data.runrunit_id) {
      delete data.runrunit_id;
    } else {
      data.runrunit_id = Number(data.runrunit_id);
    }

    if (!data.zendesk_id) {
      delete data.zendesk_id;
    } else {
      data.zendesk_id = Number(data.zendesk_id);
    }

    try {
      await fetchData(`/organizations/${organization._id}`, {
        method: "PUT",
        body: JSON.stringify(data),
      });

      set({ ...organization, ...data });

      props.setIsEditing(false);
      snackbar?.open("Informações atualizadas com sucesso!", "success");
    } catch (error) {
      snackbar?.open(
        "Ops! No momento não foi possível realizar a ação.",
        "error",
      );
    }

    setProgress(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("name", {
          required: "Informe a razão social da empresa.",
        })}
        label="Razão Social"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        error={errors?.name ? true : false}
      />
      {errors?.name && (
        <Alert severity="error">{errors.name.message?.toString()}</Alert>
      )}
      <TextField
        {...register("fantasy", {
          required: "Informe o nome fantasia da empresa.",
        })}
        label="Nome Fantasia"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
      />
      <TextField
        {...register("document", {
          required: "Informe o CNPJ da organização.",
        })}
        label="CNPJ"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        error={errors?.document ? true : false}
      />
      {errors?.document && (
        <Alert severity="error">{errors.document.message?.toString()}</Alert>
      )}
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            select
            label="Carteira"
            size="small"
            margin="dense"
            fullWidth
            variant="outlined"
          >
            {organizationsProfileLabels.map((label: string) => (
              <MenuItem key={label} value={label}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        )}
        control={control}
        name="profile"
        rules={{ required: "Selecione a carteira." }}
      />
      {errors?.profile && (
        <Alert severity="error">{errors.profile.message?.toString()}</Alert>
      )}
      <TextField
        {...register("reference", {
          required: "Informe o código/referência da organização.",
        })}
        label="Referência/Código"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        type="number"
        error={errors?.reference ? true : false}
      />
      {errors?.reference && (
        <Alert severity="error">{errors.reference.message?.toString()}</Alert>
      )}
      <Divider sx={{ mt: 2 }} />
      <Tabs
        variant="fullWidth"
        value={tab}
        onChange={handleTabChange}
        aria-label="address tab"
      >
        <Tab icon={<PlaceIcon />} />
        <Tab icon={<DeliveryDiningIcon />} />
      </Tabs>
      <TabPanel value={tab} index={0} sx={{ p: 0 }}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={1}
          sx={{ my: 1 }}
        >
          <TextField
            {...register("address.zipcode")}
            label="CEP"
            variant="outlined"
            fullWidth
            size="small"
          />
        </Stack>

        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={1}
          sx={{ my: 1 }}
        >
          <TextField
            {...register("address.street")}
            label="Endereço"
            variant="outlined"
            fullWidth
            size="small"
          />

          <TextField
            {...register("address.number")}
            label="Número"
            variant="outlined"
            fullWidth
            size="small"
          />
        </Stack>

        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={1}
          sx={{ my: 1 }}
        >
          <TextField
            {...register("address.complement")}
            label="Complemento"
            variant="outlined"
            fullWidth
            size="small"
          />

          <TextField
            {...register("address.district")}
            label="Bairro"
            variant="outlined"
            fullWidth
            size="small"
          />
        </Stack>

        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={1}
          sx={{ my: 1 }}
        >
          <TextField
            {...register("address.city")}
            label="Cidade"
            variant="outlined"
            fullWidth
            size="small"
          />

          <TextField
            {...register("address.state")}
            label="Estado"
            variant="outlined"
            fullWidth
            size="small"
          />
        </Stack>
      </TabPanel>
      <TabPanel value={tab} index={1} sx={{ p: 0 }}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={1}
          sx={{ my: 1 }}
        >
          <TextField
            {...register("delivery_address.zipcode")}
            label="CEP"
            variant="outlined"
            fullWidth
            size="small"
          />
        </Stack>

        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={1}
          sx={{ my: 1 }}
        >
          <TextField
            {...register("delivery_address.street")}
            label="Endereço"
            variant="outlined"
            fullWidth
            size="small"
          />

          <TextField
            {...register("delivery_address.number")}
            label="Número"
            variant="outlined"
            fullWidth
            size="small"
          />
        </Stack>

        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={1}
          sx={{ my: 1 }}
        >
          <TextField
            {...register("delivery_address.complement")}
            label="Complemento"
            variant="outlined"
            fullWidth
            size="small"
          />

          <TextField
            {...register("delivery_address.district")}
            label="Bairro"
            variant="outlined"
            fullWidth
            size="small"
          />
        </Stack>

        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={1}
          sx={{ my: 1 }}
        >
          <TextField
            {...register("delivery_address.city")}
            label="Cidade"
            variant="outlined"
            fullWidth
            size="small"
          />

          <TextField
            {...register("delivery_address.state")}
            label="Estado"
            variant="outlined"
            fullWidth
            size="small"
          />
        </Stack>
      </TabPanel>
      <Divider sx={{ my: 2 }} />
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            select
            label="Situação"
            size="small"
            margin="dense"
            fullWidth
            variant="outlined"
          >
            <MenuItem key="true" value="true">
              Ativo
            </MenuItem>
            <MenuItem key="false" value="false">
              Inativo
            </MenuItem>
          </TextField>
        )}
        control={control}
        name="status"
      />
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            select
            label="Estado Crítico"
            size="small"
            margin="dense"
            fullWidth
            variant="outlined"
          >
            <MenuItem key="true" value="true">
              Sim
            </MenuItem>
            <MenuItem key="false" value="false">
              Não
            </MenuItem>
          </TextField>
        )}
        control={control}
        name="critical"
      />

      <Divider sx={{ my: 2 }} />

      <DatePickerField
        name="init_date"
        register={register}
        get={getValues}
        set={setValue}
        label="Data de Início de Contrato"
      />
      <DatePickerField
        name="offboarding"
        register={register}
        get={getValues}
        set={setValue}
        label="Data de Offboarding"
      />

      <Divider sx={{ my: 2 }} />
      <TextField
        {...register("questor_branch_id")}
        label="[Questor] ID da Filial"
        variant="outlined"
        type="number"
        fullWidth
        margin="dense"
        size="small"
      />
      <TextField
        {...register("zendesk_id")}
        label="[Zendesk] ID"
        variant="outlined"
        type="number"
        fullWidth
        margin="dense"
        size="small"
      />
      <TextField
        {...register("iugu_id")}
        label="[Iugu] ID"
        variant="outlined"
        type="number"
        fullWidth
        margin="dense"
        size="small"
      />
      <Divider sx={{ my: 2 }} />
      <TextField
        {...register("runrunit_id")}
        label="[Runrun.it] ID"
        variant="outlined"
        type="number"
        fullWidth
        margin="dense"
        size="small"
      />
      <Grid container>
        <Grid item xs={12}>
          {controlledRrProjects.map((item, index) => (
            <Grid container key={index} alignItems="center" spacing={1}>
              <Grid item xs>
                <TextField
                  {...register(`runrunit_projects.${index}` as const)}
                  label="[Runrun.it] ID do Projeto"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  size="small"
                  type="number"
                />
              </Grid>
              <Grid item xs="auto">
                <Button
                  size="small"
                  aria-label="Remover Projeto"
                  onClick={() => {
                    removeProject(index);
                  }}
                  sx={{ mt: 1, p: 0, minWidth: "auto" }}
                  color="error"
                >
                  <DeleteIcon />
                </Button>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Button
            size="small"
            variant="outlined"
            fullWidth
            onClick={() => addProject(null)}
            sx={{ mt: 1 }}
          >
            Adicionar Projeto <AddIcon sx={{ fontSize: 15, ml: 1 }} />
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => {
            props.setIsEditing(false);
          }}
        >
          Cancelar
        </Button>
        <Button size="small" variant="contained" color="primary" type="submit">
          {!progress && "Salvar"}
          {progress && <CircularProgress size={12} sx={{ color: "#FFF" }} />}
        </Button>
      </Stack>
    </form>
  );
};

export default Edit;
