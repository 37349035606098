import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataGridFilter from "../../../components/DataGridFilter";
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import ViewContent from "../../../components/ViewContent";

import { Alert, AlertTitle, Link, Badge, IconButton } from "@mui/material";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { useAuth } from "../../../contexts/AuthContext";
import useDataGridFilterStore from "../../../stores/dataGridFilter";
import OrganizationsOptionsDrawer from "./drawers/options";

const Organizations = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const error = useDataGridFilterStore((state) => state.error);
  const filter = useDataGridFilterStore((state) => state.filter);
  const toggleDrawer = useDataGridFilterStore((state) => state.toggleDrawer);
  const model = "organizations";
  const mapping = {
    _id: "id",
    reference: "reference",
    name: "name",
    status: "status",
    profile: "profile",
    dp: "dp",
    contabil: "contabil",
    fiscal: "fiscal",
  };

  const columns = [
    {
      field: "reference",
      headerName: "Cód.",
      width: 100,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Link
            onClick={(e) => {
              e.preventDefault();
              navigate(`/organizations/${params.id}`);
            }}
            sx={{
              overflow: "hidden",
              justifyContent: "left",
              cursor: "pointer",
            }}
          >
            {params.row.name}
          </Link>
        );
      },
    },
    { field: "profile", headerName: "Carteira", width: 300, sortable: false },
    {
      field: "status",
      headerName: "Situação",
      width: 100,
      sortable: false,
      renderCell: (params: any) => (params.row.status ? "Ativo" : "Inativo"),
    },
    {
      field: "contabil.controle_financeiro",
      headerName: "Controle Financeiro",
      sortable: false,
      renderCell: (params: any) =>
        params.row.contabil?.controle_financeiro || "Nenhum",
    },
    {
      field: "dp.info",
      headerName: "Folha",
      sortable: false,
      renderCell: (params: any) => params.row.dp?.info || "Não",
    },
    {
      field: "dp.type",
      headerName: "Tipo Folha",
      sortable: false,
      renderCell: (params: any) => params.row.dp?.type || "Nenhum",
    },
    {
      field: "dp.adiantamento",
      headerName: "Adiantamento",
      sortable: false,
      renderCell: (params: any) => params.row.dp?.adiantamento || "",
    },
    {
      field: "fiscal.regime",
      headerName: "Regime",
      sortable: false,
      renderCell: (params: any) => params.row.fiscal?.regime || "",
    },
  ];

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <ViewContent
      title="Organizações"
      icon={<DomainOutlinedIcon sx={{ mr: 2 }} />}
      button={
        <IconButton
          onClick={() => toggleDrawer()}
          color="white"
          aria-label="options"
        >
          <Badge badgeContent={Object.keys(filter).length} color="secondary">
            <MoreHorizIcon />
          </Badge>
        </IconButton>
      }
    >
      <DataGridFilter model={model} mapping={mapping} columns={columns} />
      <OrganizationsOptionsDrawer />
    </ViewContent>
  );
};

export default Organizations;
