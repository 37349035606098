import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import CloudIcon from "@mui/icons-material/Cloud";
import ViewContent from "../../../components/ViewContent";

import { Alert, AlertTitle, Badge, IconButton, Link } from "@mui/material";

import useDataGridFilterStore from "../../../stores/dataGridFilter";
import OrganizationCloudTab from "../Organization/tabs/cloud";

const CloudListFolder = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const error = useDataGridFilterStore((state) => state.error);
  const id = searchParams.get("id");
  const path = searchParams.get("path");

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <ViewContent title="Cloud" icon={<CloudIcon sx={{ mr: 2 }} />}>
      <OrganizationCloudTab
        id={id || undefined}
        path={path || undefined}
        boxPadding={0}
      />
    </ViewContent>
  );
};

export default CloudListFolder;
