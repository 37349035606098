import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import DataGridFilter from "../../../components/DataGridFilter";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import ViewContent from "../../../components/ViewContent";

import { Alert, AlertTitle, Badge, IconButton, Link } from "@mui/material";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import UsersDrawer from "./drawers/detail";
import UsersOptionsDrawer from "./drawers/options";

import { useAuth } from "../../../contexts/AuthContext";
import useDataGridFilterStore from "../../../stores/dataGridFilter";

const Users = () => {
  const params = new URL(document.location.toString()).searchParams;
  const [userID, setUserID] = useState<any>(null);
  const navigate = useNavigate();
  const auth = useAuth();
  const readOnly =
    auth?.user.permission_level === "operator" ||
    auth?.user.permission_level === "financial"
      ? true
      : false;
  const [detailDrawer, setDetailDrawer] = useState(false);
  const error = useDataGridFilterStore((state) => state.error);
  const filter = useDataGridFilterStore((state) => state.filter);
  const toggleDrawer = useDataGridFilterStore((state) => state.toggleDrawer);
  const model = "users?is_admin=true&";
  const mapping = {
    _id: "id",
    name: "name",
    login: "login",
    status: "status",
  };

  useEffect(() => {
    const id = params.get("id");

    if (id) {
      setUserID(id);
      setDetailDrawer(true);
      navigate("/users");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const columns = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        if (readOnly) {
          return params.value;
        }

        return (
          <Link
            onClick={(e) => {
              e.preventDefault();
              setUserID(params.row.id);
              setDetailDrawer(true);
            }}
            sx={{
              overflow: "hidden",
              justifyContent: "left",
              cursor: "pointer",
            }}
          >
            {params.value}
          </Link>
        );
      },
      minWidth: 150,
    },
    {
      field: "login",
      headerName: "Login",
      width: 200,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Situação",
      width: 100,
      renderCell: (params: any) => (params.row.status ? "Ativo" : "Inativo"),
      sortable: false,
    },
  ];

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <ViewContent
      title="Usuários"
      icon={<AssignmentIndOutlinedIcon sx={{ mr: 2 }} />}
      button={
        <IconButton
          onClick={() => toggleDrawer()}
          color="white"
          aria-label="options"
        >
          <Badge badgeContent={Object.keys(filter).length} color="secondary">
            <MoreHorizIcon />
          </Badge>
        </IconButton>
      }
    >
      <DataGridFilter model={model} mapping={mapping} columns={columns} />
      {userID && (
        <UsersDrawer
          drawer={detailDrawer}
          setDrawer={setDetailDrawer}
          id={userID}
        />
      )}
      <UsersOptionsDrawer />
    </ViewContent>
  );
};

export default Users;
