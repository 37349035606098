import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Divider,
  Stack,
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import GetAppIcon from "@mui/icons-material/GetApp";

import SegmentAddFilter from "./drawer/AddFilter";
import Filters from "./Filters";

import { fetchData } from "../../../fetchData";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import { useDialog } from "../../../contexts/DialogContext";
import { useModal } from "../../../contexts/ModalContext";
import { useAuth } from "../../../contexts/AuthContext";
import { formatDate, downloadFile } from "../../../utils";

export const SegmentContext = createContext<{
  data?: any;
  set?: any;
}>({
  data: {},
});

export const useSegment = () => {
  return useContext(SegmentContext);
};

const Segment = ({
  id,
  onSave,
  onDelete,
}: {
  id?: string;
  onSave?: (_id: string) => void;
  onDelete?: () => void;
}) => {
  const now = new Date();
  const auth = useAuth();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const dialog = useDialog();
  const modal = useModal();
  const [backdrop, setBackdrop] = useState(false);
  const [editing, setEditing] = useState(true);
  const [segment, setSegment] = useState({
    total: 0,
    organizations: [],
    users: [],
  });
  const [data, setData] = useState<{
    filters: any[];
    title: string;
    condition: string;
  }>({
    filters: [],
    title: "",
    condition: "and",
  });

  const init = async () => {
    setBackdrop(true);

    try {
      const segment = await fetchData(`/segments/${id}`, {
        method: "GET",
      });
      const payload = {
        filters: segment.filters,
        title: segment.title,
        condition: segment.condition,
      };

      setData(payload);
      setEditing(false);
      handleSegmentReport(payload);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) init();
  }, [id]);

  const handleNullValues = () => {
    const newFilter = [...data.filters];
    let nullValues = 0;

    newFilter.map((filter: any, index: number) => {
      if (filter.value === "") {
        newFilter[index] = {
          ...newFilter[index],
          error: true,
        };
        nullValues++;
      }
    });

    if (!nullValues) return false;

    setData({ ...data, filters: newFilter });

    return true;
  };

  const exportToCsv = (type: string) => {
    let headers: Array<any> = [];
    let dataCsv: Array<any> = [];

    if (type === "users") {
      headers = ["Nome,Login,E-mail,Organizações"];

      dataCsv = segment.users.reduce((acc: any, user) => {
        const {
          name,
          login,
          email,
          client,
        }: {
          name: string;
          login: string;
          email: Array<any>;
          client: Array<any>;
        } = user;

        const formattedEmails = Array.isArray(email)
          ? email.map((email: any) => email.value).join(";")
          : "";

        const formattedClients = Array.isArray(client) ? client.join(";") : "";

        acc.push([name, login, formattedEmails, formattedClients].join(","));
        return acc;
      }, []);
    }

    if (type === "organizations") {
      headers = ["Organização,Código,CNPJ,Carteira"];

      dataCsv = segment.organizations.reduce((acc: any, user) => {
        const {
          name,
          reference,
          document,
          profile,
          address,
        }: {
          name: string;
          reference: number;
          document: string;
          profile: string;
          address: any;
        } = user;

        acc.push(
          [
            name,
            reference,
            document,
            profile,
            `${address?.street}, ${address?.number} - ${address?.complement} - ${address?.district} - ${address?.city} - ${address?.state} - ${address?.zipcode}`,
          ].join(","),
        );
        return acc;
      }, []);
    }

    downloadFile({
      data: [...headers, ...dataCsv].join("\n"),
      fileName: `${type === "users" ? "contatos" : "organizacoes"}-${
        data.title
      }.csv`,
      fileType: "text/csv",
    });
  };

  const handleSegmentReport = async (payload: any = null) => {
    if (handleNullValues()) return;

    setBackdrop(true);

    try {
      const response = await fetchData(`/segments/report`, {
        method: "POST",
        body: JSON.stringify(payload ? payload : data),
      });

      setSegment(response);
      setEditing(false);
    } catch (error) {
      console.log(error);
    }

    setBackdrop(false);
  };

  const handleSegmentSave = async () => {
    setBackdrop(true);

    const payload = {
      title: data.title,
      condition: data.condition,
      filters: data.filters,
    };

    try {
      const response = await fetchData(`/segments${id ? `/${id}` : ""}`, {
        method: !id ? "POST" : "PUT",
        body: JSON.stringify(payload),
      });

      snackbar?.open("Segmento salvo com sucesso!", "success");
      dialog?.open("Editar Segmento", <Segment id={response._id} />);

      if (onSave) onSave(response._id);
    } catch (error) {
      console.log(error);
      snackbar?.open(
        "Ops! No momento não foi possível realizar a ação.",
        "error",
      );
    }

    setBackdrop(false);
  };

  const handleDeleteSegment = async () => {
    setBackdrop(true);

    try {
      await fetchData(
        `/segments/${id}`,
        {
          method: "DELETE",
        },
        false,
      );

      snackbar?.open("Segmento excluído com sucesso!", "success");
      if (onDelete) onDelete();
      dialog?.close();
    } catch (error) {
      console.log(error);
      snackbar?.open(
        "Ops! No momento não foi possível realizar a ação.",
        "error",
      );
    }

    setBackdrop(false);
  };

  const DeleteConfirmationModal = () => {
    return (
      <Box>
        <Stack direction="row" spacing={2}>
          <Button
            size="small"
            variant="outlined"
            sx={{ flex: 1 }}
            onClick={() => {
              modal?.close();
            }}
          >
            Cancelar
          </Button>
          <Button
            size="small"
            variant="contained"
            sx={{ flex: 1 }}
            onClick={() => {
              handleDeleteSegment();
              modal?.close();
            }}
          >
            SIM
          </Button>
        </Stack>
      </Box>
    );
  };

  return (
    <SegmentContext.Provider
      value={{
        data: data,
        set: setData,
      }}
    >
      <Backdrop
        open={backdrop}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack
        direction="row"
        sx={{ p: 3 }}
        spacing={2}
        justifyContent="space-between"
      >
        {editing && (
          <>
            <TextField
              label="Nome do Segmento"
              placeholder="Dê um nome ao segmento"
              sx={{ minWidth: 300 }}
              value={data.title}
              onChange={(e) => {
                setData({ ...data, title: e.target.value });
              }}
            />
            <Button
              size="small"
              variant="contained"
              disabled={data.filters.length === 0 || data.title === ""}
              onClick={() => {
                handleSegmentReport();
              }}
            >
              Analisar Segmento
            </Button>
          </>
        )}
        {!editing && (
          <>
            <Typography variant="h5" fontWeight={500} sx={{ py: 1 }}>
              Analise seu segmento
            </Typography>
            {auth?.user.permission_level === "manager" && (
              <Stack direction="row" spacing={2}>
                {id && (
                  <Button
                    size="small"
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      modal?.open(
                        "Deseja excluir este segmento?",
                        <DeleteConfirmationModal />,
                      );
                    }}
                  >
                    Excluir
                  </Button>
                )}
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  Editar
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  disabled={!segment.total}
                  onClick={() => {
                    handleSegmentSave();
                  }}
                >
                  Salvar
                </Button>
              </Stack>
            )}
          </>
        )}
      </Stack>
      <Divider />
      <Stack spacing={3} sx={{ p: 3 }}>
        {editing && (
          <>
            <Filters />
            <SegmentAddFilter />
          </>
        )}
        {!editing && (
          <Stack direction="row" spacing={2}>
            <Paper elevation={2} sx={{ p: 2, width: "100%" }}>
              <Stack spacing={2} direction="row">
                <Box flex={1}>
                  <Typography variant="h6" fontWeight={500}>
                    {data.title}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Stack direction="row" spacing={2}>
                    <Typography variant="h3" fontWeight={700} color="primary">
                      {segment.total}
                    </Typography>
                    <Box>
                      <Typography variant="body1" fontWeight={400}>
                        contatos no seu segmento
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={300}
                        color="text.main"
                      >
                        em {formatDate(now.toString())}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
                <Stack spacing={2}>
                  <Button
                    startIcon={<GetAppIcon />}
                    variant="outlined"
                    size="small"
                    sx={{ flex: 1 }}
                    onClick={() => {
                      exportToCsv("organizations");
                    }}
                  >
                    Organizações
                  </Button>
                  <Button
                    startIcon={<GetAppIcon />}
                    variant="outlined"
                    size="small"
                    sx={{ flex: 1 }}
                    onClick={() => {
                      exportToCsv("users");
                    }}
                  >
                    Contatos
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          </Stack>
        )}
      </Stack>
    </SegmentContext.Provider>
  );
};

export default Segment;
