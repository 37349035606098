import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import ViewContent from "../../../components/ViewContent";

import {
  Alert,
  AlertTitle,
  Chip,
  Stack,
  Link,
  Typography,
  IconButton,
  Badge,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import DataGridFilter from "../../../components/DataGridFilter";

import { fetchData } from "../../../fetchData";
import { formatDate } from "../../../utils";
import { useAuth } from "../../../contexts/AuthContext";
import useDataGridFilterStore from "../../../stores/dataGridFilter";
import MessagingOptionsDrawer from "./drawers/options";

const Messaging = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const error = useDataGridFilterStore((state) => state.error);
  const filter = useDataGridFilterStore((state) => state.filter);
  const toggleDrawer = useDataGridFilterStore((state) => state.toggleDrawer);
  const model = "messaging";
  const mapping = {
    _id: "id",
    title: "title",
    time_mode: "time_mode",
    state: "state",
    created_at: "created_at",
    sent_at: "sent_at",
  };

  const columns = [
    {
      field: "title",
      headerName: "Comunicado",
      flex: 1,
      renderCell: (params: any) => {
        const ChipComponent = () => (
          <Chip
            color={params.row.state ? "success" : "default"}
            label={params.row.state ? "Enviado" : "Rascunho"}
            size="small"
            sx={{ textTransform: "uppercase", ml: 1 }}
          />
        );

        if (auth?.user.permission_level !== "manager" && !params.row.state) {
          return (
            <>
              {params.row.title}
              <ChipComponent />
            </>
          );
        }

        return (
          <>
            <Link
              onClick={(e) => {
                e.preventDefault();

                navigate(`/messaging/${params.id}`);
              }}
              sx={{
                overflow: "hidden",
                justifyContent: "left",
                cursor: "pointer",
              }}
            >
              {params.row.title}
            </Link>
            <ChipComponent />
          </>
        );
      },
      minWidth: 150,
    },
    {
      field: "created_at",
      headerName: "Data",
      width: 200,
      renderCell: (params: any) => {
        return (
          <Stack direction="column">
            <>
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {!params.row.state && "Criado em"}
                {params.row.state && "Enviado em"}
              </Typography>
              <Typography variant="body2" component="div">
                {!params.row.state &&
                  formatDate(params.row.created_at, "D/M/Y H:i")}
                {params.row.state &&
                  formatDate(params.row.sent_at, "D/M/Y H:i")}
              </Typography>
            </>
          </Stack>
        );
      },
    },
  ];

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <ViewContent
      title="Comunicados"
      icon={<CampaignOutlinedIcon sx={{ mr: 2 }} />}
      button={
        <IconButton
          onClick={() => toggleDrawer()}
          color="white"
          aria-label="options"
        >
          <Badge badgeContent={Object.keys(filter).length} color="secondary">
            <MoreHorizIcon />
          </Badge>
        </IconButton>
      }
    >
      <DataGridFilter
        model={model}
        mapping={mapping}
        columns={columns}
        sort="created_at"
        order="desc"
      />
      <MessagingOptionsDrawer />
    </ViewContent>
  );
};

export default Messaging;
