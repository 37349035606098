import { useNavigate } from "react-router-dom";
import DataGridFilter from "../../../components/DataGridFilter";
import GroupIcon from "@mui/icons-material/GroupOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import ViewContent from "../../../components/ViewContent";

import {
  Alert,
  AlertTitle,
  Chip,
  Stack,
  Link,
  IconButton,
  Badge,
} from "@mui/material";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { navigateToOrganization } from "../../../utils/navigateToOrganization";
import useDataGridFilterStore from "../../../stores/dataGridFilter";
import PersonsOptionsDrawer from "./drawers/options";

const Persons = () => {
  const navigate = useNavigate();
  const error = useDataGridFilterStore((state) => state.error);
  const filter = useDataGridFilterStore((state) => state.filter);
  const toggleDrawer = useDataGridFilterStore((state) => state.toggleDrawer);
  const model = "users?is_admin=false&";
  const mapping = {
    _id: "id",
    name: "name",
    status: "status",
    client: "client",
    phone: "phone",
    email: "email",
    login: "login",
  };

  const columns = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Link
            onClick={(e) => {
              e.preventDefault();
              navigate(`/persons/${params.id}`);
            }}
            sx={{
              overflow: "hidden",
              justifyContent: "left",
              cursor: "pointer",
            }}
          >
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: "status",
      headerName: "Situação",
      width: 100,
      sortable: false,
      renderCell: (params: any) => (params.row.status ? "Ativo" : "Inativo"),
    },
    {
      field: "client",
      headerName: "Organização",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params: any) => {
        if (params.value) {
          return (
            <Stack direction="row" spacing={1}>
              {params.value.map((value: any) => (
                <Chip
                  key={value}
                  label={value}
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    navigateToOrganization(value, navigate);
                  }}
                />
              ))}
            </Stack>
          );
        } else {
          return "";
        }
      },
    },
    {
      field: "phone",
      headerName: "Telefone",
      flex: 1,
      renderCell: (params: any) => {
        if (params.value) {
          return (
            <Stack direction="row" spacing={1}>
              {params.value.map((value: any) => {
                if (value.value) {
                  return (
                    <Chip
                      icon={
                        value.label == "mobile" ? (
                          <WhatsAppIcon />
                        ) : (
                          <PhoneIcon />
                        )
                      }
                      key={value.value}
                      label={value.value}
                      size="small"
                    />
                  );
                } else {
                  return "";
                }
              })}
            </Stack>
          );
        } else {
          return "";
        }
      },
      minWidth: 150,
      sortable: false,
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 1,
      renderCell: (params: any) => {
        if (params.value) {
          return (
            <Stack direction="column" spacing={1}>
              {params.value.map((value: any) => {
                if (value.value) {
                  return (
                    <Link
                      href={`mailto:${value.value}`}
                      key={value.value}
                      sx={{ mb: -1, p: 0 }}
                    >
                      {value.value}
                    </Link>
                  );
                } else {
                  return "";
                }
              })}
            </Stack>
          );
        } else {
          return "";
        }
      },
      minWidth: 150,
      sortable: false,
    },
    { field: "login", headerName: "Login", minWidth: 150, sortable: false },
  ];

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <ViewContent
      title="Pessoas"
      icon={<GroupIcon sx={{ mr: 2 }} />}
      button={
        <IconButton
          onClick={() => toggleDrawer()}
          color="white"
          aria-label="options"
        >
          <Badge badgeContent={Object.keys(filter).length} color="secondary">
            <MoreHorizIcon />
          </Badge>
        </IconButton>
      }
    >
      <DataGridFilter model={model} mapping={mapping} columns={columns} />
      <PersonsOptionsDrawer />
    </ViewContent>
  );
};

export default Persons;
