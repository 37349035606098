import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Unstable_Grid2 as Grid,
  TextField,
  Button,
  Stack,
  Divider,
  CircularProgress,
  MenuItem,
  Alert,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { useOrganization } from "..";
import { useAuth } from "../../../../contexts/AuthContext";

import { fetchData } from "../../../../fetchData";
import SelectChipField from "../../../../components/SelectChipField";

type FormValues = {
  info: string;
  type: string;
  adiantamento: string;
  sindPatronal: string;
  sindClasse: string;
  fopagDataPGTO: string;
  fopagDataADTO: string;
  funcCLT: string;
};

const OrganizationDpTab = () => {
  const auth = useAuth();
  const snackbar = useSnackbar();
  const { data: organization, set } = useOrganization();
  const [progress, setProgress] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      info: organization.dp?.info || "",
      type: organization.dp?.type || "",
      adiantamento: organization.dp?.adiantamento || "",
      sindPatronal: organization.dp?.sindPatronal || "",
      sindClasse: organization.dp?.sindClasse || "",
      fopagDataPGTO: organization.dp?.fopagDataPGTO || "",
      fopagDataADTO: organization.dp?.fopagDataADTO || "",
      funcCLT: organization.dp?.funcCLT || "",
    },
  });

  async function onSubmit(data: any) {
    setProgress(true);
    const payload = { ...organization, dp: data };

    try {
      await fetchData(`/organizations/${organization._id}`, {
        method: "PUT",
        body: JSON.stringify(payload),
      });

      set(payload);

      setIsEditing(false);
      snackbar?.open(
        "Informações contábeis atualizadas com sucesso!",
        "success",
      );
    } catch (error) {
      snackbar?.open(
        "Ops! No momento não foi possível realizar a ação.",
        "error",
      );
    }

    setProgress(false);
  }

  return (
    <Box sx={{ p: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid xs={12} lg={4}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Folha de Pagamento"
                  size="small"
                  margin="dense"
                  disabled={!isEditing}
                  fullWidth
                  variant="outlined"
                >
                  {["Com alteração", "Sem alteração", "Não Possui"].map(
                    (label: string) => (
                      <MenuItem key={label} value={label}>
                        {label}
                      </MenuItem>
                    ),
                  )}
                </TextField>
              )}
              control={control}
              name="info"
              rules={{ required: "Selecione a Folha de Pagamento" }}
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Adiantamento"
                  size="small"
                  margin="dense"
                  disabled={!isEditing}
                  fullWidth
                  variant="outlined"
                >
                  {["Sim", "Não"].map((label: string) => (
                    <MenuItem key={label} value={label}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              control={control}
              name="adiantamento"
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Funcionário CLT?"
                  size="small"
                  margin="dense"
                  disabled={!isEditing}
                  fullWidth
                  variant="outlined"
                >
                  {["Sim", "Não"].map((label: string) => (
                    <MenuItem key={label} value={label}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              control={control}
              name="funcCLT"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid xs={12} lg={8}>
            <TextField
              {...register("sindPatronal")}
              label="Sindicato Patronal"
              variant="outlined"
              fullWidth
              disabled={!isEditing}
              margin="dense"
              size="small"
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField
              {...register("fopagDataPGTO")}
              label="Data de pagamento FOPAG"
              variant="outlined"
              fullWidth
              disabled={!isEditing}
              type="number"
              margin="dense"
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid xs={12} lg={8}>
            <TextField
              {...register("sindClasse")}
              label="Sindicato de Classe"
              variant="outlined"
              fullWidth
              disabled={!isEditing}
              margin="dense"
              size="small"
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField
              {...register("fopagDataADTO")}
              label="Data de pagamento ADTO"
              variant="outlined"
              fullWidth
              disabled={!isEditing}
              type="number"
              margin="dense"
              size="small"
            />
          </Grid>
        </Grid>

        {auth?.user.permission_level === "manager" && (
          <>
            <Divider sx={{ my: 2 }} />
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={1}
              justifyContent={isEditing ? "flex-end" : "flex-start"}
            >
              {!isEditing && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  <EditIcon fontSize="small" sx={{ mr: 1 }} /> Editar
                </Button>
              )}
              {isEditing && (
                <>
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setIsEditing(false);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {!progress && "Salvar"}
                    {progress && (
                      <CircularProgress size={12} sx={{ color: "#FFF" }} />
                    )}
                  </Button>
                </>
              )}
            </Stack>
          </>
        )}
      </form>
    </Box>
  );
};

export default OrganizationDpTab;
