import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import FeedIcon from "@mui/icons-material/Feed";
import ViewContent from "../../../components/ViewContent";
import { Badge, ChipPropsColorOverrides, IconButton } from "@mui/material";

import {
  Alert,
  AlertTitle,
  Chip,
  Stack,
  Link,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import DataGridFilter from "../../../components/DataGridFilter";

import { formatDate } from "../../../utils";
import pad from "../../../utils/pad";
import useDataGridFilterStore from "../../../stores/dataGridFilter";
import useFopagStore from "../../../stores/fopag";
import StatusChip from "../../../components/StatusChip";

import FopagOptionsDrawer from "./drawer/options";

const Fopag = () => {
  const setDrawer = useFopagStore((state) => state.toggleDetailDrawer);
  const error = useDataGridFilterStore((state) => state.error);
  const filter = useDataGridFilterStore((state) => state.filter);
  const toggleDrawer = useDataGridFilterStore((state) => state.toggleDrawer);
  const model = "hrm/fopag";
  const mapping = {
    _id: "id",
    status: "status",
    reference: "reference",
    month: "month",
    sent_in: "sent_in",
    draft: "draft",
    organization: "organization",
  };

  const columns = [
    {
      field: "reference",
      headerName: "Cód.",
      width: 60,
      sortable: false,
    },
    {
      field: "organization",
      headerName: "Cliente",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            <Link
              onClick={() => setDrawer(params.row)}
              component="button"
              sx={{
                overflow: "hidden",
                justifyContent: "left",
                cursor: "pointer",
              }}
            >
              {params.row.organization[0].name}
            </Link>
          </>
        );
      },
      sortable: false,
    },
    {
      field: "month",
      minWidth: 150,
      sortable: false,
      headerName: "Competência",
      renderCell: (params: any) => {
        const competence = new Date(params.row.month);
        return (
          <Link component="button" onClick={() => setDrawer(params.row)}>
            {`${pad(competence.getMonth() + 1, 2)}/${competence.getFullYear()}`}
          </Link>
        );
      },
    },
    {
      field: "sent_in",
      sortable: false,
      headerName: "Data",
      width: 140,
      renderCell: (params: any) => {
        if (params.row.status === "editing") {
          return formatDate(params.row.month, "D/M/Y H:i");
        } else {
          return formatDate(params.row.sent_in, "D/M/Y H:i");
        }
      },
    },
    {
      field: "status",
      sortable: false,
      headerName: "Situação",
      width: 140,
      renderCell: (params: any) => {
        const status: string = params.row.status;

        return <StatusChip status={status} />;
      },
    },
  ];

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <>
      <ViewContent
        title="Folhas de Pagamento"
        icon={<FeedIcon sx={{ mr: 2 }} />}
        button={
          <IconButton
            onClick={() => toggleDrawer()}
            color="white"
            aria-label="options"
          >
            <Badge badgeContent={Object.keys(filter).length} color="secondary">
              <MoreHorizIcon />
            </Badge>
          </IconButton>
        }
      >
        <DataGridFilter
          model={model}
          mapping={mapping}
          columns={columns}
          order="desc"
        />
      </ViewContent>
      <FopagOptionsDrawer />
    </>
  );
};

export default Fopag;
