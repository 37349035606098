import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DataGrid from "../../../components/DataGrid";
import SegmentIcon from "@mui/icons-material/Segment";
import ViewContent from "../../../components/ViewContent";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import {
  Alert,
  AlertTitle,
  Chip,
  Stack,
  Link,
  Button,
  IconButton,
  Badge,
} from "@mui/material";

import { fetchData } from "../../../fetchData";
import { formatDate } from "../../../utils";
import { useDialog } from "../../../contexts/DialogContext";
import useDataGridFilterStore from "../../../stores/dataGridFilter";

import Segment from "../Segment";

import DataGridFilter from "../../../components/DataGridFilter";
import SegmentsOptionsDrawer from "./drawers/options";

const Segments = () => {
  const navigate = useNavigate();
  const dialog = useDialog();
  const error = useDataGridFilterStore((state) => state.error);
  const filter = useDataGridFilterStore((state) => state.filter);
  const getData = useDataGridFilterStore((state) => state.getData);
  const toggleDrawer = useDataGridFilterStore((state) => state.toggleDrawer);
  const model = "segments";
  const mapping = {
    _id: "id",
    title: "title",
    filters: "filters",
    created_at: "created_at",
  };

  const columns = [
    {
      field: "title",
      headerName: "Nome",
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Link
            onClick={(e) => {
              e.preventDefault();
              dialog?.open(
                "Editar Segmento",
                <Segment
                  id={params.row.id}
                  onSave={(_id) => {
                    console.log(_id);
                  }}
                  onDelete={() => {
                    getData();
                  }}
                />,
              );
            }}
            sx={{
              overflow: "hidden",
              justifyContent: "left",
              cursor: "pointer",
            }}
          >
            {params.value}
          </Link>
        );
      },
      minWidth: 150,
    },
    {
      field: "filters",
      headerName: "Nº de Filtros",
      flex: 1,
      renderCell: (params: any) => {
        return params.value.length;
      },
      minWidth: 150,
      sortable: false,
    },
    {
      field: "created_at",
      headerName: "Criado em",
      flex: 1,
      renderCell: (params: any) => {
        return formatDate(params.value);
      },
      minWidth: 150,
      sortable: false,
    },
  ];

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <ViewContent
      title="Segmentos"
      icon={<SegmentIcon sx={{ mr: 2 }} />}
      button={
        <IconButton
          onClick={() => toggleDrawer()}
          color="white"
          aria-label="options"
        >
          <Badge badgeContent={Object.keys(filter).length} color="secondary">
            <MoreHorizIcon />
          </Badge>
        </IconButton>
      }
    >
      <DataGridFilter
        model={model}
        mapping={mapping}
        columns={columns}
        sort="created_at"
        order="desc"
      />
      <SegmentsOptionsDrawer />
    </ViewContent>
  );
};

export default Segments;
