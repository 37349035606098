import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Typography,
  Stack,
  Link,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import BusinessIcon from "@mui/icons-material/Business";
import PlaceIcon from "@mui/icons-material/Place";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SimCardIcon from "@mui/icons-material/SimCard";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FlagSharpIcon from "@mui/icons-material/FlagSharp";

import { useOrganization } from ".";
import { useAuth } from "../../../contexts/AuthContext";
import { fetchData } from "../../../fetchData";
import { formatDate } from "../../../utils";

import Edit from "./edit";

const Profile = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const organization = useOrganization();
  const { data } = organization;
  const [isEditing, setIsEditing] = useState(false);
  const [legal, setLegal] = useState<any>({});

  const legalResponsible = async () => {
    try {
      const person = await fetchData(`/users/${data.legal_responsible}`, {
        method: "GET",
      });
      setLegal(person);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsEditing(false);
    if (!data.legal_responsible) return;
    legalResponsible();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  return (
    <Card>
      <CardContent>
        {!isEditing && (
          <div style={{ textAlign: "center" }}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 2 }}
              spacing={2}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {data.reference}
              </Typography>

              {data.profile && <Chip label={data.profile} />}

              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {data.status && <VerifiedUserIcon color="success" />}
                {!data.status && <RemoveModeratorIcon color="error" />}
                <Typography
                  variant="body1"
                  color={`${data.status ? "success" : "error"}.main`}
                  sx={{ ml: 1 }}
                >
                  {data.status && "Ativo"}
                  {!data.status && "Inativo"}
                </Typography>
              </Stack>
            </Stack>

            <Divider sx={{ my: 3 }} />

            {data.critical && (
              <div>
                <FlagSharpIcon color="error" />
                <Typography
                  variant="caption"
                  component="div"
                  fontWeight="bold"
                  sx={{ mb: 3 }}
                >
                  Situação crítica
                </Typography>
              </div>
            )}

            {data.document && (
              <div>
                <BusinessIcon color="primary" />
                <Typography variant="body1">{data.document}</Typography>
              </div>
            )}

            {data.legal_responsible && legal.name && (
              <div>
                <AdminPanelSettingsIcon color="primary" sx={{ mt: 3 }} />
                <Link
                  onClick={() => {
                    navigate(`/persons/${legal._id}`);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <Typography variant="body1">{legal.name}</Typography>
                </Link>
              </div>
            )}

            {data.digital_certificate?.expiricy && (
              <>
                <SimCardIcon color="primary" sx={{ mt: 3 }} />
                <Typography variant="caption" component="div" fontWeight="bold">
                  A1 válido até
                </Typography>
                <Typography variant="body1">
                  {formatDate(data.digital_certificate?.expiricy)}
                </Typography>
              </>
            )}

            {data.init_date && (
              <>
                <CalendarMonthIcon color="primary" sx={{ mt: 3 }} />
                <Typography variant="caption" component="div" fontWeight="bold">
                  Início de Contrato
                </Typography>
                <Typography variant="body1">
                  {formatDate(data.init_date, "D/M/Y")}
                </Typography>
              </>
            )}

            {data.address && data.address.state && data.address.city && (
              <div>
                <PlaceIcon color="primary" sx={{ mt: 3 }} />
                <Typography variant="body1">
                  {data.address.street}
                  {data.address.number && `, ${data.address.number}`}
                  {(data.address.street || data.address.number) && <br />}
                  {data.address.complement && data.address.complement}
                  {data.address.complement && <br />}
                  {data.address.district && `${data.address.district} - `}
                  {`${data.address.city} - ${data.address.state}`}
                  {data.address.zipcode && <br />}
                  {data.address.zipcode && `CEP: ${data.address.zipcode}`}
                </Typography>
              </div>
            )}

            {data.delivery_address && data.delivery_address.zipcode && (
              <div>
                <DeliveryDiningIcon color="primary" sx={{ mt: 3 }} />
                <Typography variant="body1">
                  {data.delivery_address.street}, {data.delivery_address.number}
                  <br />
                  {data.delivery_address.complement &&
                    data.delivery_address.complement}
                  {data.delivery_address.complement && <br />}
                  {`${data.delivery_address.district} - ${data.delivery_address.city} - ${data.delivery_address.state}`}
                  {data.delivery_address.zipcode && <br />}
                  CEP: {data.delivery_address.zipcode}
                </Typography>
              </div>
            )}

            {auth?.user.permission_level === "manager" && (
              <>
                <Divider sx={{ my: 3 }} />
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  <EditIcon fontSize="small" sx={{ mr: 1 }} /> Editar
                </Button>
              </>
            )}
          </div>
        )}

        {isEditing && <Edit setIsEditing={setIsEditing} />}
      </CardContent>
    </Card>
  );
};

export default Profile;
