import { useEffect, useState } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";

import {
  AppBar,
  Avatar,
  Badge,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon,
  AssignmentOutlined as AssignmentIcon,
  AssignmentInd as AssignmentIndIcon,
  AssignmentIndOutlined as AssignmentIndOutlinedIcon,
  BarChartOutlined as BarChartIcon,
  CampaignOutlined as CampaignOutlinedIcon,
  Campaign as CampaignIcon,
  CategoryOutlined as CategoryOutlinedIcon,
  DonutSmall as DonutSmallIcon,
  DonutSmallOutlined as DonutSmallOutlinedIcon,
  Domain as DomainIcon,
  DomainOutlined as DomainOutlinedIcon,
  GroupOutlined as GroupIcon,
  InsertEmoticon as InsertEmoticonIcon,
  LogoutOutlined as LogoutOutlinedIcon,
  Menu as MenuIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
  ToggleOffOutlined as ToggleOffOutlinedIcon,
  ToggleOnOutlined as ToggleOnOutlinedIcon,
  Segment as SegmentIcon,
  Feed as FeedIcon,
  AssignmentTurnedInOutlined as AssignmentTurnedInOutlinedIcon,
  ContactPageOutlined as ContactPageOutlinedIcon,
} from "@mui/icons-material";

import InboxIcon from "@mui/icons-material/Inbox";

import Logo from "../../assets/images/symbol.svg";

import { stringAvatar } from "../../utils/stringAvatar";

import { useAuth } from "../../contexts/AuthContext";
import { useModal } from "../../contexts/ModalContext";
import { useDialog } from "../../contexts/DialogContext";

import Snackbar from "../../components/Snackbar";
import Modal from "../../components/Modal";
import Dialog from "../../components/Dialog";

import AddPerson from "../../views/Admin/Dashboard/modals/addPerson";
import AddOrganization from "../../views/Admin/Dashboard/modals/addOrganization";
import AddUser from "../../views/Admin/Dashboard/modals/addUser";
import AddNps from "../../views/Admin/Dashboard/modals/addNps";
import AddMessage from "../../views/Admin/Dashboard/modals/addMessage";
import Search from "../../views/Admin/Dashboard/Search";
import Segment from "../../views/Admin/Segment";
import FopagDetailDrawer from "../../views/Admin/Fopag/drawer/detail";

import { useThemeMode } from "../../contexts/ThemeModeContext";

const drawerWidth = 70;

const firstNav = [
  {
    title: "Dashboard",
    icon: <CategoryOutlinedIcon />,
    link: "/",
  },
  {
    title: "Pessoas",
    icon: <GroupIcon />,
    link: "persons",
  },
  {
    title: "Organizações",
    icon: <DomainOutlinedIcon />,
    link: "organizations",
  },
  {
    title: "Usuários",
    icon: <AssignmentIndOutlinedIcon />,
    link: "users",
  },
];

const secondNav = [
  {
    title: "Pesquisa CSAT",
    icon: <InsertEmoticonIcon />,
    link: "surveys/csat",
  },
  {
    title: "Pesquisas NPS",
    icon: <DonutSmallOutlinedIcon />,
    link: "surveys/nps",
  },
  {
    title: "Segmentos",
    icon: <SegmentIcon />,
    link: "segments",
  },
  // {
  //   title: "Tarefas",
  //   icon: <AssignmentIcon />,
  //   path: "",
  // },
  {
    title: "Comunicados",
    icon: <CampaignOutlinedIcon />,
    link: "messaging",
  },
  {
    title: "Fopag",
    icon: <ContactPageOutlinedIcon />,
    link: "fopag",
  },
  // {
  //   title: "Tipos de Tarefa",
  //   icon: <AssignmentTurnedInOutlinedIcon />,
  //   link: "task-types",
  // },
];

function Admin(props: any) {
  const { window } = props;
  const navigate = useNavigate();
  const theme = useThemeMode();
  const auth = useAuth();
  const modal = useModal();
  const dialog = useDialog();
  const location = useLocation();
  const [anchorElUser, setAnchorElUser] = useState<null>(null);
  const [anchorElOpts, setAnchorElOpts] = useState<null>(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    auth?.tokenVerify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenOptsMenu = (event: any) => {
    setAnchorElOpts(event.currentTarget);
  };

  const handleCloseOptsMenu = () => {
    setAnchorElOpts(null);
  };

  const handleAddItem = (schema: string) => {
    switch (schema) {
      case "organization":
        modal?.open("Criar Organização", <AddOrganization />);
        break;
      case "user":
        modal?.open("Criar Usuário", <AddUser />);
        break;
      case "nps":
        modal?.open("Criar Pesquisa NPS", <AddNps />);
        break;
      case "segment":
        dialog?.open("Criar Segmento", <Segment />);
        break;
      case "messaging":
        modal?.open("Criar Campanha", <AddMessage />);
        break;

      default:
        modal?.open("Criar Pessoa", <AddPerson />);
        break;
    }

    handleCloseOptsMenu();
  };

  // const toggleTheme = () => {
  // };

  const toolbar = (
    <>
      <IconButton
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: "none" } }}
      >
        <MenuIcon />
      </IconButton>
      <Box sx={{ flexGrow: 0 }}>
        <Search />
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "none", md: "flex" },
          paddingLeft: "2em",
        }}
      >
        <Tooltip title="Caixa de entrada">
          <IconButton>
            {/* <Badge color="quaternary" badgeContent={1}> */}
            <Badge color="quaternary">
              <InboxIcon sx={{ color: "#CCC" }} />
            </Badge>
          </IconButton>
        </Tooltip>
        {auth?.user.permission_level === "manager" && (
          <Tooltip title="Adição rápida">
            <IconButton
              onClick={(e) => {
                handleOpenOptsMenu(e);
              }}
              sx={{ marginLeft: ".2em" }}
            >
              <AddCircleOutlineOutlinedIcon sx={{ color: "text.primary" }} />
            </IconButton>
          </Tooltip>
        )}
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElOpts}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={Boolean(anchorElOpts)}
          onClose={handleCloseOptsMenu}
        >
          <MenuItem
            onClick={() => {
              handleAddItem("person");
            }}
          >
            <PersonIcon sx={{ color: "text.primary", marginRight: ".5em" }} />
            <Typography textAlign="center">Pessoa</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleAddItem("organization");
            }}
          >
            <DomainIcon sx={{ color: "text.primary", marginRight: ".5em" }} />
            <Typography textAlign="center">Organização</Typography>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handleAddItem("user");
            }}
          >
            <AssignmentIndIcon
              sx={{ color: "text.primary", marginRight: ".5em" }}
            />
            <Typography textAlign="center">Usuário</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleAddItem("nps");
            }}
          >
            <DonutSmallIcon sx={{ marginRight: ".5em" }} />
            <Typography textAlign="center">Pesquisa NPS</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleAddItem("segment");
            }}
          >
            <SegmentIcon sx={{ color: "text.primary", marginRight: ".5em" }} />
            <Typography textAlign="center">Segmento</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleAddItem("messaging");
            }}
          >
            <CampaignIcon sx={{ color: "text.primary", marginRight: ".5em" }} />
            <Typography textAlign="center">Comunicado</Typography>
          </MenuItem>
        </Menu>
      </Box>
      <Box
        sx={{
          flexGrow: 0,
          paddingLeft: "1em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            paddingLeft: "1em",
            display: { xs: "none", md: "flex" },
          }}
        >
          <Typography
            variant="body2"
            textAlign="center"
            sx={{ color: "text.primary" }}
          >
            Olá, {auth?.user.nickname}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 0, paddingLeft: "1em" }}>
          <Tooltip title={auth?.user.name}>
            <IconButton
              onClick={(e) => {
                handleOpenUserMenu(e);
              }}
              sx={{ p: 0 }}
            >
              {/* <Avatar alt="John Doe" src="/static/images/avatar/2.jpg" /> */}
              <Avatar {...stringAvatar(auth?.user.name)} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {/* <MenuItem onClick={handleCloseUserMenu}>
              <SettingsIcon
                sx={{ color: "text.primary", marginRight: ".5em" }}
              />
              <Typography textAlign="center">Preferências</Typography>
            </MenuItem>
            <Divider /> */}
            <MenuItem onClick={auth?.logout}>
              <LogoutOutlinedIcon
                sx={{ color: "text.primary", marginRight: ".5em" }}
              />
              <Typography textAlign="center">Sair</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </>
  );

  const drawer = (
    <Stack spacing={1} sx={{ px: 2 }}>
      <Box sx={{ py: 2 }}>
        <img
          src={Logo}
          width="30"
          alt={process.env.REACT_APP_NAME}
          style={{ marginTop: "5px" }}
        />
      </Box>
      <Divider />
      {firstNav.map((item, index) => (
        <Tooltip
          key={`fnav${index}`}
          title={item.title}
          placement="right"
          onClick={() => {
            navigate(item.link);
          }}
        >
          <IconButton color="secondary">{item.icon}</IconButton>
        </Tooltip>
      ))}
      <Divider />
      {secondNav.map((item, index) => (
        <Tooltip
          key={`snav${index}`}
          title={item.title}
          placement="right"
          onClick={() => {
            navigate(item.link);
          }}
        >
          <IconButton color="secondary">{item.icon}</IconButton>
        </Tooltip>
      ))}
      <Divider />
      <Tooltip title="Mode escuro" placement="right">
        <IconButton
          color="secondary"
          onClick={() => {
            theme?.change();
          }}
        >
          {theme?.mode === "light" && <ToggleOffOutlinedIcon />}
          {theme?.mode === "dark" && <ToggleOnOutlinedIcon />}
        </IconButton>
      </Tooltip>
    </Stack>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "background.default",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "background.default",
          backgroundImage: "none",
          boxShadow: "none",
          paddingTop: ".5em",
        }}
      >
        <Toolbar>{toolbar}</Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Outlet />
        <Snackbar />
        <Modal />
        <Dialog />
        <FopagDetailDrawer />
      </Box>
    </Box>
  );
}

export default Admin;
