import { useEffect, useRef, Component, createRef } from "react";

import {
  Stack,
  Typography,
  Button,
  Unstable_Grid2 as Grid,
  Avatar,
  LinearProgress,
  LinearProgressProps,
  Box,
} from "@mui/material";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { v4 as uuidv4 } from "uuid";

import api from "../../services/api";

import { humanFileSize } from "../../utils";

interface Props {
  baseUrl: string;
  allowedFiles: string;
  callback?: (response: any) => void;
}

interface State {
  dragActive: boolean;
  uploadedFiles: Array<any>;
  inputRef: any;
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number },
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

class SingleFileUpload extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // Inicialize os estados, se necessário
    this.state = {
      dragActive: false,
      uploadedFiles: [],
      inputRef: createRef(),
    };
  }

  handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive: false });
    }
  };

  handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ dragActive: false });

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      this.handleUpload(e.dataTransfer.files);
    }
  };

  handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      this.handleUpload(e.target.files);
    }
  };

  onButtonClick = () => {
    if (this.state.inputRef.current != null) {
      this.state.inputRef.current.click();
    }
  };

  handleUpload = (files: any) => {
    const newFiles = Array.from(files).map((file: any) => ({
      file,
      id: uuidv4(),
      name: file.name.normalize("NFD").replace(/[^a-zA-Z0-9.\s]/g, ""),
      size: humanFileSize(file.size),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    this.setState({ uploadedFiles: this.state.uploadedFiles.concat(newFiles) });

    newFiles.forEach(this.processUpload);
  };

  updateFile = (id: string, data: any) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map((uploadedFile: any) => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  processUpload = (uploadedFile: any) => {
    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);

    api()
      .post(this.props.baseUrl, data, {
        onUploadProgress: (e) => {
          const progress = Math.round((e.loaded * 100) / e.total);

          this.updateFile(uploadedFile.id, { progress });
        },
      })
      .then((response) => {
        this.updateFile(uploadedFile.id, {
          uploaded: true,
        });

        if (this.props.callback) {
          this.props.callback(response);
        }
      })
      .catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true,
        });
      });
  };

  render() {
    return (
      <>
        {this.state.uploadedFiles.length === 0 && (
          <form id="form-file-upload" onSubmit={(e) => e.preventDefault()}>
            <input
              ref={this.state.inputRef}
              hidden
              accept={this.props.allowedFiles}
              type="file"
              onChange={this.handleChange}
            />
            <label>
              <Stack
                spacing={1}
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                  p: 2,
                  border: "1px dashed #000",
                  borderColor: "primary.main",
                  borderRadius: 1,
                  backgroundColor: this.state.dragActive
                    ? "#009aff2b"
                    : "transparent",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={this.onButtonClick}
                  disabled={this.state.dragActive}
                >
                  Selecione um arquivo .PFX
                </Button>
              </Stack>
            </label>
          </form>
        )}
        {this.state.uploadedFiles &&
          this.state.uploadedFiles.map((file: any) => (
            <Stack
              key={file.id}
              direction="row"
              spacing={2}
              alignContent="center"
              justifyContent="center"
              alignItems="center"
              sx={{
                p: 1,
                borderRadius: 2,
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: "primary.main",
              }}
            >
              <Avatar
                sx={{
                  fontSize: 14,
                  fontWeight: "bold",
                  bgcolor: "primary.light",
                }}
              >
                {file.name.split(".").pop().toLocaleUpperCase()}
              </Avatar>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    width: "170px",
                    fontWeight: "bold",
                    wordWrap: "break-word",
                  }}
                  component="div"
                  variant="caption"
                >
                  {file.name}
                </Typography>
                <Typography component="div" variant="caption">
                  {file.size}
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel value={file.progress} />
                </Box>
              </Box>
            </Stack>
          ))}
      </>
    );
  }
}

export default SingleFileUpload;
