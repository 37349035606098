//Create pallete on https://material.io/resources/color/
/* eslint-disable @typescript-eslint/no-empty-interface */
import { useMemo, useState } from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { ThemeModeContext } from "../contexts/ThemeModeContext";

interface ColorOverrides {
  tertiary: true;
  quaternary: true;
  quinary: true;
  white: true;
  note: true;
}

declare module "@mui/material/styles" {
  interface Palette {
    tertiary: Palette["primary"];
    quaternary: Palette["primary"];
    quinary: Palette["primary"];
    white: Palette["primary"];
    note: Palette["primary"];
  }
  interface PaletteOptions {
    tertiary: PaletteOptions["primary"];
    quaternary: PaletteOptions["primary"];
    quinary: PaletteOptions["primary"];
    white: PaletteOptions["primary"];
    note: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/Badge" {
  interface BadgePropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides extends ColorOverrides {}
}

const theme = (mode: any) => {
  return responsiveFontSizes(
    createTheme({
      typography: {
        fontFamily: ["Poppins", "sans-serif", "Arial"].join(","),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              fontSize: "1em",
              borderRadius: 7,
              padding: ".8em 2em",
            },
            sizeSmall: {
              fontSize: ".8em",
              padding: ".2em 1.2em",
            },
            sizeMedium: {
              fontSize: ".9em",
              padding: ".5em 1.6em",
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: "7px !important",
            },
          },
        },
      },
      palette: {
        mode,
        primary: {
          main: "#009aff",
          light: "#69caff",
          dark: "#006dcb",
          contrastText: "#fff",
        },
        secondary: {
          main: mode === "light" ? "#1f252c" : "#C4C9CD",
          light: mode === "light" ? "#464d55" : "#E2E6EA",
          dark: mode === "light" ? "#000000" : "#83878B",
        },
        tertiary: {
          main: "#BB86FC",
          light: "#DBC0FD",
          dark: "#6B4C91",
        },
        quaternary: {
          main: "#f5a826",
          light: "#ffd95b",
          dark: "#bd7900",
        },
        quinary: {
          main: "#a7b2c3",
          light: "#d9e4f6",
          dark: "#788393",
        },
        white: {
          main: "#fafafa",
          light: "#ffffff",
          dark: "#dedede",
        },
        note: {
          main: mode === "light" ? "#fefcdc" : "#fefcdc3d",
        },
        background: {
          default: mode === "light" ? "#F5F5F5" : "#121212",
        },
      },
    }),
  );
};

const ThemesProvider = (props: any) => {
  const localMode =
    localStorage.getItem(`${process.env.REACT_APP_KEYNAME}themeMode`) ||
    "light";
  const [mode, setMode] = useState(localMode);

  const change = async () => {
    const newMode = mode === "light" ? "dark" : "light";

    setMode(newMode);

    localStorage.setItem(`${process.env.REACT_APP_KEYNAME}themeMode`, newMode);
  };

  const value = useMemo(
    () => ({
      mode,
      change,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mode],
  );

  return (
    <ThemeModeContext.Provider value={value}>
      <ThemeProvider theme={theme(mode)}>{props.children}</ThemeProvider>
    </ThemeModeContext.Provider>
  );
};

export default ThemesProvider;
